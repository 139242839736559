import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import { bonfireOutline, bulbOutline, bugOutline } from "ionicons/icons";
import { IotaboardChangeLog } from "../../services/versioning";

export interface ChangeLogItemProps {
  changeLog: IotaboardChangeLog;
  key?: any;
}

export const ChangeLogItem: React.FC<ChangeLogItemProps> = (props) => {
  return (
    <IonItem key={props.key}>
      <IonIcon
        icon={
          props.changeLog.type == "breaking-change"
            ? bonfireOutline
            : props.changeLog.type == "enhancement"
            ? bulbOutline
            : bugOutline
        }
        size="small"
        slot="start"
      />
      <IonLabel>
        <h3
          style={{
            whiteSpace: "break-spaces",
          }}
          className="ion-text-wrap"
        >
          {props.changeLog.message}
        </h3>
      </IonLabel>
    </IonItem>
  );
};
