import { isPlatform } from "@ionic/react";
import { ConfigurationModel } from "./configuration-model";

const IOTABOARD_CONFIGURATION_KEY = "config";

const createDefaultConfiguration = (): ConfigurationModel => {
  if (isPlatform("capacitor")) {
    // For capacitor apps
    return {
      serverHost: "iotaboard.iotatech.id",
      httpPort: 443,
      mqttPort: 20883,
      useHttps: true,
    };
  }
  return {
    // For hosted static web PWA
    serverHost: window.location.hostname ?? "iotaboard.iotatech.id",
    httpPort: window.location.port ? parseInt(window.location.port) : 443,
    mqttPort: 20883,
    useHttps: window.location.protocol == "https:",
  };
};

export const loadConfiguration = (): ConfigurationModel => {
  const json: string | null = localStorage.getItem(IOTABOARD_CONFIGURATION_KEY);
  try {
    if (json) {
      const confiugration = JSON.parse(json) as ConfigurationModel;
      if (!confiugration.serverHost) {
        confiugration.serverHost =
          window.location.host ?? "iotaboard.iotatech.id";
      }
      return confiugration;
    }
  } catch (err) {
    // in case json is invalid
    //reset localstorage
    localStorage.clear();
    saveConfiguration(createDefaultConfiguration());
  }
  return createDefaultConfiguration();
};

export const saveConfiguration = (configuration: ConfigurationModel) => {
  const configurationJson = JSON.stringify(configuration);
  localStorage.setItem(IOTABOARD_CONFIGURATION_KEY, configurationJson);
};

export const resetConfiguration = () => {
  localStorage.clear();
};
