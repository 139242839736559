import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonText,
  IonThumbnail,
  isPlatform,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonPopover,
} from "@ionic/react";
import { AssetPermissionsPageParams } from "./asset-permissions-page-params";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { person, qrCodeOutline, settingsOutline } from "ionicons/icons";
import { AssetAccessPermission } from "../../services/iotaboard-client/types/asset-access-permission";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { AccessLevel } from "../../services/iotaboard-client/types/access-level";
import { UserInfo } from "../../services/iotaboard-client/types/user-info";
import {
  UserOptionPopover,
  UserOptionPopoverProps,
} from "./user-option-popover";
import { AssetUserInfo } from "../../services/iotaboard-client/types/asset-user-info";
import { PermissionViewModel } from "./permission-view-model";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";

import "../../theme/common.css";
import { ScanQrModal, ScanQrModalProps } from "./scan-qr-modal";

export const AssetPermissionsPage: React.FC = () => {
  const params = useParams<AssetPermissionsPageParams>();

  const [permissions, setPermissions] = useState<AssetAccessPermission[]>();

  const [searchedUser, setSearchedUser] = useState<
    AssetUserInfo | undefined | null
  >();

  const [searchLoading, setSearchLoading] = useState<boolean>();

  const [presentAlert, dismissAlert] = useIonAlert();

  const [presentLoadingModal, dismissLoadingModal] = useIonLoading();

  const [searchUsername, setSearchUsername] = useState<string>();

  const [isScanningQr, setIsScanningQr] = useState<boolean>();

  const [optionsPopoverPermission, setOptionsPopoverPermission] =
    useState<PermissionViewModel>();
  const [presentUserOptionsPopover, dismissUserOptionsPopover] = useIonPopover(
    UserOptionPopover,
    {
      finish: (data, role) => dismissUserOptionsPopover(data, role),
      permission: optionsPopoverPermission,
    } as UserOptionPopoverProps
  );

  const loadPermission = async () => {
    if (params.assetId) {
      const response = await defaultIotaboardClient.getAssetAccessPermissions(
        params.assetId
      );
      if (response.statusCode == 200) {
        setPermissions(response.data);
        console.log(response.data);
      }
    }
  };

  useEffect(() => {
    loadPermission();
  }, []);

  const userList = (accessLevel: AccessLevel, label: string) => {
    if (permissions) {
      const guestList = permissions.filter((p) => p.accessLevel == accessLevel);

      return (
        guestList.length > 0 && (
          <>
            <IonList>
              <IonListHeader>{label}</IonListHeader>
              {guestList.map((p) =>
                userItem({
                  id: p.id,
                  user: p.user,
                  username: p.userUsername,
                  accessLevel: p.accessLevel,
                })
              )}
            </IonList>
          </>
        )
      );
    }
    return false;
  };

  const searchUser = async (username?: string) => {
    if (!username) {
      setSearchedUser(undefined);
      return;
    }
    setSearchLoading(true);
    const response = await defaultIotaboardClient.getUserInfo(username);
    if (response.statusCode == 200) {
      setSearchedUser(response.data);
    } else if (response.statusCode == 404) {
      setSearchedUser(null);
    }
    setSearchLoading(false);
  };

  const updatePermission = async (
    username: string,
    accessLevel: AccessLevel
  ) => {
    await presentLoadingModal("Updating permission");
    const response = await defaultIotaboardClient.addAssetPermission({
      username: username,
      accessLevel: accessLevel,
      assetId: params.assetId,
    });
    await dismissLoadingModal();
    loadPermission();
    if (response.statusCode != 200) {
      await presentAlert({
        subHeader: "Unable to Update Permission",
        message: response.message,
        buttons: ["OK"],
      });
    }
  };

  const removePermission = async (username: string) => {
    await presentLoadingModal("Removing permission");
    const response = await defaultIotaboardClient.removeAssetPermission(
      params.assetId,
      username
    );
    await dismissLoadingModal();
    loadPermission();
    if (response.statusCode != 200) {
      await presentAlert({
        subHeader: "Unable to Remove Permission",
        message: response.message,
        buttons: ["OK"],
      });
    }
  };

  const userItem = (permission: PermissionViewModel) => {
    return (
      <IonItem
        id={permission.id}
        button={permission.accessLevel != AccessLevel.owner}
        onClick={
          permission.accessLevel == AccessLevel.owner
            ? undefined
            : (ev) => {
                setOptionsPopoverPermission(permission);
                presentUserOptionsPopover({
                  event: ev as any,
                  onDidDismiss: (ev) => {
                    if (ev.detail.role && (ev.detail.data as string)) {
                      const username = ev.detail.data;
                      const operation = ev.detail.role;
                      if (operation == "make-guest") {
                        updatePermission(username, AccessLevel.guest);
                      } else if (operation == "make-admin") {
                        updatePermission(username, AccessLevel.admin);
                      } else if (operation == "remove") {
                        removePermission(username);
                      }
                    }
                  },
                });
              }
        }
      >
        <IonIcon slot="start" icon={person} />
        <IonLabel>
          <h3>
            {permission.user.firstName} {permission.user.lastName}
          </h3>
          <p>{permission.username}</p>
        </IonLabel>
      </IonItem>
    );
  };

  const searchResult = () => {
    if (searchLoading) {
      return (
        <IonItem>
          <IonThumbnail slot="start">
            <IonSkeletonText animated style={{ width: "48px" }} />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{ width: "200px" }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{ width: "160px" }} />
            </p>
          </IonLabel>
        </IonItem>
      );
    }
    if (searchedUser === null) {
      return (
        <div className="ion-text-center">
          <IonNote>Username does not match any registered user</IonNote>
        </div>
      );
    }
    if (searchedUser) {
      return userItem({
        id: "0",
        accessLevel: undefined,
        user: searchedUser,
        username: searchedUser.username,
      });
    }
    return false;
  };

  const [presentScanQrModal, dismissScanQrModal] = useIonModal(ScanQrModal, {
    onResult: (data, role) => dismissScanQrModal(data, role),
  } as ScanQrModalProps);

  const scanQrCode = async () => {
    setIsScanningQr(true);
    presentScanQrModal({
      showBackdrop: false,
      onDidDismiss: (ev) => {
        if (ev.detail.role == "username" && ev.detail.data) {
          const username = ev.detail.data as string;
          setSearchUsername(username);
        }
        setIsScanningQr(false);
      },
    });
  };

  useEffect(() => {
    searchUser(searchUsername);
  }, [searchUsername]);

  if (isScanningQr) {
    return <IonPage />;
  }
  return (
    <IonPage>
      <MainAppBar title="Manage Asset Permission" />
      <IonContent className="ion-padding">
        <div className="ion-padding">
          <IonText>
            Enter the username of user you want to grant access to
          </IonText>
        </div>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonSearchbar
                placeholder="Username"
                debounce={200}
                value={searchUsername}
                onIonInput={(ev) => {
                  setSearchUsername(ev.target.value as string);
                }}
              />
            </IonCol>
            <IonCol size="auto">
              <IonButton color={"medium"} onClick={() => scanQrCode()}>
                <IonIcon icon={qrCodeOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{searchResult()}</IonCol>
          </IonRow>
        </IonGrid>

        <p>Existing Permissions</p>
        {userList(AccessLevel.owner, "Owner")}
        {userList(AccessLevel.admin, "Admins")}
        {userList(AccessLevel.guest, "Guests")}
      </IonContent>
    </IonPage>
  );
};
