import {
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
} from "@ionic/react";
import { lockClosed, lockOpen, wifi, wifiOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import {
  Credentials,
  getBlePairingService,
} from "../../../services/ble-pairing";
import { WifiItem } from "../../../services/ble-pairing/utilities";
import { loadConfiguration } from "../../../services/configuration";
import { delay } from "../../../utilities/delay";
import { CrossSlideInterfacing } from "../cross-slide-interfacing";
import { PreviousSlideButton } from "../PreviousSlideButton/PreviousSlideButton";

interface Props {
  requestPassswordCallback: (
    ssid: string,
    returnIndex: number
  ) => Promise<string | undefined>;
  requestConnectCallback: (credentials: Credentials) => void;
}

export const WifiListSlide: React.FC<CrossSlideInterfacing & Props> = (
  props
) => {
  const [wifiList, setWifiList] = useState<WifiItem[]>([]);

  // Effect to start reading
  useEffect(() => {
    if (props.currentIndex == 2) {
      console.log("WiFi list started");
      setWifiList([]);
      loadWifiList();
    } else {
      unsubscribeWifiList();
    }
  }, [props.currentIndex]);

  const unsubscribeWifiList = () => {
    const blePairingService = getBlePairingService();
    blePairingService.onDeviceWiFiListUpdated(undefined);
  };

  const loadWifiList = async () => {
    const blePairingService = getBlePairingService();
    const connectedBleId = blePairingService.getConnectedBleDeviceId();
    if (connectedBleId) {
      await delay(2000);
      const wifiList = await blePairingService.getDeviceWiFiList(
        connectedBleId
      );
      blePairingService.onDeviceWiFiListUpdated((newList) => {
        setWifiList(newList);
      });
      setWifiList(wifiList);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="ion-margin-top ion-padding-top"
        style={{ textAlign: "left" }}
      >
        <PreviousSlideButton
          onClick={() => {
            props.swiperInstance?.slidePrev();
          }}
        />
      </div>
      <div className="ion-margin ion-padding-top">
        <IonIcon
          icon={wifiOutline}
          style={{ fontSize: "80px" }}
          color="primary"
        />
      </div>
      <div className="ion-margin ion-padding-bottom">
        <IonTitle className="ion-margin-bottom">Let's Pick a WiFi Network</IonTitle>
        <IonText>
          The network you choose will be passed to device to communicate with
          Iotaboard server.
        </IonText>
      </div>
      {wifiList.length == 0 && <IonSpinner />}
      {wifiList.length > 0 && (
        <IonList>
          <IonListHeader>Available Networks</IonListHeader>
          {wifiList.map((wifiItem) => (
            <IonItem
              button
              key={wifiItem.ssid}
              onClick={async () => {
                if (wifiItem.authRequired) {
                  // Request password from the next slide
                  const password = await props.requestPassswordCallback(
                    wifiItem.ssid,
                    2
                  );

                  if (password) {
                    const configuration = loadConfiguration();
                    const credentials: Credentials = {
                      server: `mqtt://${configuration.serverHost}`,
                      port: configuration.mqttPort,
                      ssid: wifiItem.ssid,
                      psk: password,
                    };

                    props.requestConnectCallback(credentials);
                  } else {
                    props.swiperInstance?.slideTo(2);
                  }
                } else {
                  const configuration = loadConfiguration();
                  const credentials: Credentials = {
                    server: `mqtt://${configuration.serverHost}`,
                    port: configuration.mqttPort,
                    ssid: wifiItem.ssid,
                    psk: "",
                  };

                  props.requestConnectCallback(credentials);
                }
              }}
            >
              <IonIcon
                slot="start"
                icon={wifiItem.authRequired ? lockClosed : lockOpen}
              />
              <IonLabel>{wifiItem.ssid}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}
    </div>
  );
};
