import { IonContent, IonList, IonItem, IonIcon, IonText } from "@ionic/react";
import { pencilOutline, shareSocial, trashBinOutline } from "ionicons/icons";

export interface DashboardOptionsPopoverProps {
  dashboardId: string;
  dismiss: (data?: any, role?: string) => void;
}

export const DashboardOptionsPopover: React.FC<DashboardOptionsPopoverProps> = (
  props
) => {
  console.log(props);
  return (
    <IonContent className="">
      <IonList>
        <IonItem
          button
          onClick={() => {
            props.dismiss(props.dashboardId, "rename");
          }}
        >
          <IonIcon slot="start" icon={pencilOutline} />
          <IonText>Rename</IonText>
        </IonItem>
        <IonItem
          button
          onClick={() => {
            props.dismiss(props.dashboardId, "share");
          }}
        >
          <IonIcon icon={shareSocial} slot="start" />
          <IonText>Share Dashboard</IonText>
        </IonItem>
        <IonItem
          button
          onClick={() => {
            props.dismiss(props.dashboardId, "delete");
          }}
        >
          <IonIcon icon={trashBinOutline} color="danger" slot="start" />
          <IonText color="danger">Delete Dashboard</IonText>
        </IonItem>
      </IonList>
    </IonContent>
  );
};
