import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  loadConfiguration,
  saveConfiguration,
} from "../../services/configuration";
import { ConfigurationModel } from "../../services/configuration/configuration-model";

import "./SettingsPage.css";

interface SettingsPageProps {
  returnUrl?: string;
}

export const SettingsPage: React.FC<SettingsPageProps> = (props) => {
  const history = useHistory();

  const [configuration, setConfiguration] = useState<ConfigurationModel>(
    loadConfiguration()
  );

  const finish = () => {
    if (props.returnUrl) {
      history.replace(props.returnUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="container">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCard className="settings-card">
                <IonCardHeader>
                  <IonGrid>
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      <IonCardTitle>Iotaboard Settings</IonCardTitle>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem lines="inset">
                    <IonLabel position="floating">Server URL</IonLabel>
                    <IonInput
                      type="text"
                      autofocus
                      placeholder="iotaboard.iotatech.id"
                      value={configuration?.serverHost}
                      onIonChange={(ev) => {
                        setConfiguration({
                          ...configuration,
                          serverHost: ev.target.value as string,
                        });
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonGrid>
                    <IonRow className="ion-align-items-end">
                      <IonItem lines="inset">
                        <IonLabel position="floating">HTTP Port</IonLabel>
                        <IonInput
                          type="number"
                          placeholder="443"
                          min={1}
                          max={65535}
                          value={configuration.httpPort}
                          onIonChange={(ev) => {
                            setConfiguration({
                              ...configuration,
                              httpPort: ev.target.value as number,
                            });
                          }}
                        />
                      </IonItem>
                      <IonItem lines="inset">
                        <IonCheckbox
                          slot="start"
                          checked={configuration.useHttps}
                          onIonChange={(ev) => {
                            setConfiguration({
                              ...configuration,
                              useHttps: ev.target.checked,
                            });
                          }}
                        />
                        <IonLabel>Is HTTPS</IonLabel>
                      </IonItem>
                    </IonRow>
                  </IonGrid>

                  <IonItem lines="inset">
                    <IonLabel position="floating">MQTT Port</IonLabel>
                    <IonInput
                      type="number"
                      placeholder="20883"
                      min={1}
                      max={65535}
                      value={configuration.mqttPort}
                      onIonChange={(ev) => {
                        setConfiguration({
                          ...configuration,
                          mqttPort: ev.target.value as number,
                        });
                      }}
                    />
                  </IonItem>
                </IonCardContent>
                <IonGrid>
                  <IonRow className="ion-justify-content-end">
                    <IonButton fill="clear" color="danger" onClick={finish}>
                      Cancel
                    </IonButton>
                    <IonButton
                      fill="clear"
                      color="primary"
                      onClick={() => {
                        saveConfiguration(configuration!);
                        finish();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};
