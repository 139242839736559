import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTitle,
} from "@ionic/react";
import { lockClosed } from "ionicons/icons";
import React, { useState } from "react";
import { CrossSlideInterfacing } from "../cross-slide-interfacing";
import { PreviousSlideButton } from "../PreviousSlideButton/PreviousSlideButton";

interface Props {
  passwordResolver?: (password?: string) => void;
  returnIndex: number;
  ssid: string;
}

export const WifiPasswordSlide: React.FC<CrossSlideInterfacing & Props> = (
  props
) => {
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
      <div
        className="ion-margin ion-padding-vertical"
        style={{ textAlign: "left" }}
      >
        <PreviousSlideButton
          onClick={() => {
            props.passwordResolver?.(undefined);
            props.swiperInstance?.slideTo(props.returnIndex);
          }}
        />
      </div>
      <div className="ion-margin ion-padding">
        <IonIcon
          icon={lockClosed}
          style={{ fontSize: "80px" }}
          color="primary"
        />
      </div>
      <div className="ion-margin">
        <IonTitle>The network '{props.ssid}' is secured</IonTitle>
      </div>
      <div className="ion-margin">
        <IonText>
          Please specify the password for the device to connect to the network.
        </IonText>
      </div>
      <div className="ion-margin ion-padding">
        <IonItem fill="outline">
          <IonLabel position="stacked">Password</IonLabel>
          <IonInput
            value={password}
            onIonChange={(ev) => {
              setPassword(ev.target.value as string);
            }}
            type={showPassword ? "text" : "password"}
          ></IonInput>
        </IonItem>
        <IonItem style={{ "--background": "transparent" }}>
          <IonCheckbox
            slot="start"
            onIonChange={(ev) => {
              setShowPassword(ev.target.checked);
            }}
          ></IonCheckbox>
          <IonLabel>Show Password</IonLabel>
        </IonItem>
      </div>
      <div className="ion-margin ion-padding-horizontal">
        <IonButton
          disabled={!password || password.length < 8}
          expand="block"
          onClick={() => {
            props.swiperInstance?.slideTo(props.returnIndex);
            props.passwordResolver?.(password);
          }}
        >
          CONNECT
        </IonButton>
      </div>
    </div>
  );
};
