import { BleClient, ScanResult } from "@capacitor-community/bluetooth-le";
import { isPlatform } from "@ionic/react";
import { delay } from "../../utilities/delay";
import {
  objectToNumbers,
  rawWifiListFromArrayBuffer,
  stringFromBuffer,
  WifiItem,
  wifiListFromRaw,
} from "./utilities";

let devicePairingSingleton: BleDevicePairingService | undefined;

export const getBlePairingService = (): BleDevicePairingService => {
  if (!devicePairingSingleton) {
    devicePairingSingleton = new BleDevicePairingService();
  }
  return devicePairingSingleton;
};

interface Options {
  errorCallback?: (e: Error) => void;
}

interface InitOptions extends Options {
  bleDeviceListUpdated: (results: ScanResult[]) => void;
}

interface ConnectOptions extends Options {
  bleDeviceId: string;
  onDisconnect?: (deviceId: string, intentionallyDisconnect: boolean) => void;
  onConnectSucces?: (deviceId: string) => void;
}

export interface Credentials {
  ssid: string;
  psk?: string;
  server: string;
  port: number;
}

interface DonePairingMessage {
  donePairing: boolean;
}

interface DeviceInfoReply {
  status: DeviceConnectResults;
  rssi: number;
  ip: string;
  ssid: string;
}

const WIFI_LIST_SERVICE_UUID = "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
const WIFI_LIST_CHARACTERISTIC_UUID = "d75b6b0c-5be7-434f-9653-4d0ebadfe578";
const DEVICE_ID_SERVICE_UUID = "4fafc202-1fb5-459e-8fcc-c5c9c331914b";
const DEVICE_ID_CHARACTERISTIC_UUID = "d77b6b0c-5be7-434f-9653-4d0ebadfe578";
const COMMAND_SERVICE_UUID = "4fafc201-1fb5-459e-8fcc-c5c9c331914c";
const COMMAND_CHARACTERISTIC_UUID = "beb5483e-36e1-4688-b7f5-ea07361b26a8";
const INFO_SERVICE_UUID = "4fafc201-1fb5-459e-8fcc-c5c9c331914d";
const INFO_CHARACTERISTIC_UUID = "beb5483e-36e1-4688-b7f5-ea07361b26a7";

export enum DeviceConnectResults {
  IOTATECH_DEVICE_CONNECTED = 1,
  IOTATECH_WIFI_DISCONNECTED = 2,
  IOTATECH_WIFI_WRONG_PASSWORD = 3,
  IOTATECH_MQTT_REFUSE_PROTOCOL = 4,
  IOTATECH_MQTT_REFUSE_ID_REJECTED = 5,
  IOTATECH_MQTT_REFUSE_SERVER_UNAVAILABLE = 6,
  IOTATECH_MQTT_REFUSE_BAD_USERNAME = 7,
  IOTATECH_MQTT_REFUSE_NOT_AUTHORIZED = 8,
}

export interface InitializationResult {
  success: boolean;
  error?: Error;
}

export class BleDevicePairingService {
  private scanResults: ScanResult[] = [];
  private connectedBleDeviceId?: string;
  private connectedIotaboardDeviceId?: string;

  getConnectedBleDeviceId = () => this.connectedBleDeviceId;
  getConnectedIotaboardDeviceId = () => this.connectedIotaboardDeviceId;

  private intentionallyDisconnect = false;

  async initialize(): Promise<InitializationResult> {
    try {
      await BleClient.initialize({
        androidNeverForLocation: true,
      });

      if (isPlatform("android")) {
        if (!(await BleClient.isEnabled())) {
          await BleClient.enable();
        }
      }

      return {
        success: true,
      };
    } catch (err) {
      return {
        success: false,
        error: err as Error,
      };
    }
  }

  async openAppSettings() {
    await BleClient.openAppSettings();
  }

  async startScanBleDevices(options: InitOptions): Promise<boolean> {
    this.scanResults = [];
    try {
      const initResult = await this.initialize();
      if (!initResult.success) {
        throw initResult.error;
      }

      await delay(1000);

      await BleClient.requestLEScan(
        {
          namePrefix: "IOTA",
          allowDuplicates: true,
        },
        (scanResult) => {
          if (
            !this.scanResults.some(
              (r) => r.device.deviceId == scanResult.device.deviceId
            )
          ) {
            this.scanResults.push(scanResult);
            this.scanResults.sort((a, b) => {
              if (!a.rssi || !b.rssi) {
                return 0;
              }
              return a.rssi - b.rssi;
            });
          }
          options.bleDeviceListUpdated(this.scanResults);
        }
      );

      return true;
    } catch (e) {
      if (options.errorCallback != null) {
        options.errorCallback(e as unknown as Error);
      }

      return false;
    }
  }

  async stopScanBleDevices(options?: Options) {
    try {
      await BleClient.stopLEScan();
      await this.disconnectAllBleDevices();
    } catch (e) {
      if (options && options.errorCallback) {
        options.errorCallback(e as unknown as any);
      }
    }
  }

  private infoCallbacks: ((data: DeviceInfoReply) => void)[] = [];

  addOnInfoChanged(callback: (data: DeviceInfoReply) => void) {
    this.infoCallbacks.push(callback);
  }

  clearOnInfoCallbacks() {
    this.infoCallbacks.length = 0;
  }

  async connectToBleDevice(options: ConnectOptions) {
    try {
      await BleClient.connect(options.bleDeviceId, (deviceId) => {
        if (this.connectedBleDeviceId == deviceId) {
          this.connectedBleDeviceId = undefined;
          this.connectedIotaboardDeviceId = undefined;
          options.onDisconnect &&
          options.onDisconnect(deviceId, this.intentionallyDisconnect);
        }
      });
      
      await BleClient.startNotifications(
        options.bleDeviceId,
        WIFI_LIST_SERVICE_UUID,
        WIFI_LIST_CHARACTERISTIC_UUID,
        (value) => {
          const wifiList: WifiItem[] = wifiListFromRaw(
            rawWifiListFromArrayBuffer(value.buffer)
          );
          this._onWiFiListUpdatedCallback &&
            this._onWiFiListUpdatedCallback(wifiList);
        }
      );

      await BleClient.startNotifications(
        options.bleDeviceId,
        INFO_SERVICE_UUID,
        INFO_CHARACTERISTIC_UUID,
        (dataView) => {
          const json = JSON.parse(stringFromBuffer(dataView.buffer));
          for (const callback of this.infoCallbacks) {
            callback(json);
          }
          console.log("Info Service", json);
        }
      );

      this.connectedBleDeviceId = options.bleDeviceId;
      this.connectedIotaboardDeviceId = await this.readIotaboardDeviceId(this.connectedBleDeviceId!);
      options.onConnectSucces && options.onConnectSucces(options.bleDeviceId);
    } catch (e) {
      if (options.errorCallback) {
        options.errorCallback(e as unknown as Error);
      }
    }
  }

  private _onWiFiListUpdatedCallback?: (list: WifiItem[]) => void;

  onDeviceWiFiListUpdated(callback?: (list: WifiItem[]) => void) {
    this._onWiFiListUpdatedCallback = callback;
  }

  async getDeviceWiFiList(bleDeviceId: string): Promise<WifiItem[]> {
    if (!this.connectedBleDeviceId) {
      throw new Error("Device is not connected");
    }
    const value = await BleClient.read(
      bleDeviceId,
      WIFI_LIST_SERVICE_UUID,
      WIFI_LIST_CHARACTERISTIC_UUID
    );

    return wifiListFromRaw(rawWifiListFromArrayBuffer(value.buffer));
  }

  private async readIotaboardDeviceId(bleDeviceId: string): Promise<string> {
    let iotaboardDeviceId = "";

    while (!iotaboardDeviceId) {
      const iotaboardDeviceIdData = await BleClient.read(
        bleDeviceId,
        DEVICE_ID_SERVICE_UUID,
        DEVICE_ID_CHARACTERISTIC_UUID
      );
      try {
        iotaboardDeviceId = JSON.parse(
          stringFromBuffer(iotaboardDeviceIdData.buffer)
        )["deviceId"];
      } catch {
        console.log("Device ID read error");
      }
    }

    return iotaboardDeviceId;
  }

  async disconnectAllBleDevices() {
    console.log("Disconnecting from all devices");
    const devices = await BleClient.getConnectedDevices([]);

    // Set the intentionally disconnect flag
    this.intentionallyDisconnect = true;

    // Disconnect from any connected devices
    for (const device of devices) {
      await BleClient.disconnect(device.deviceId);
    }

    while ((await BleClient.getConnectedDevices([])).length) {
      await new Promise((r) => setTimeout(r, 100));
    }

    // Remove all callbacks
    this.infoCallbacks.length = 0;

    // Rest the intentionally disconnect flag
    this.intentionallyDisconnect = false;

    console.log("Devices disconected", devices);
  }

  async sendWifiCredentials(bleDeviceId: string, credentials: Credentials) {
    const numbers = objectToNumbers(credentials);
    await BleClient.write(
      bleDeviceId,
      COMMAND_SERVICE_UUID,
      COMMAND_CHARACTERISTIC_UUID,
      new DataView(numbers.buffer)
    );
  }

  async sendDonePairing(bleDeviceId: string) {
    const numbers = objectToNumbers({
      donePairing: true,
    } as DonePairingMessage);

    await BleClient.write(
      bleDeviceId,
      COMMAND_SERVICE_UUID,
      COMMAND_CHARACTERISTIC_UUID,
      new DataView(numbers.buffer)
    );
  }
}
