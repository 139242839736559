import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { filter, gridOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { APIOperationResult } from "../../services/iotaboard-client/types/api-operation-result";
import {
  DashboardTemplate,
  DashboardTemplateType,
} from "../../services/iotaboard-client/types/dashboard-template";
import { PreviousSlideButton as BackButton } from "../PairDeviceSwipePage/PreviousSlideButton/PreviousSlideButton";

export enum DashboardSearchFilters {
  name = "name",
  tags = "tags",
}

export const AddDashboardPage: React.FC = () => {
  const history = useHistory();

  const [dashboardSearchFilter, setDashboardSerchFilter] =
    useState<DashboardSearchFilters>(DashboardSearchFilters.name);
  const [presentAlert, dismissAlert] = useIonAlert();

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [searchPromise, setSearchPromise] =
    useState<Promise<APIOperationResult<DashboardTemplate[]>>>();
  const [searchResults, setSearchResults] = useState<DashboardTemplate[]>();

  const [presentToast, dismissToast] = useIonToast();

  const [presentLoading, dismissLoading] = useIonLoading();

  const chooseFilters = async () => {
    await dismissAlert();
    await presentAlert({
      subHeader: "Filter search by",
      inputs: [
        {
          type: "radio",
          checked: dashboardSearchFilter == DashboardSearchFilters.name,
          value: DashboardSearchFilters.name,
          label: "Name",
        },
        {
          type: "radio",
          checked: dashboardSearchFilter == DashboardSearchFilters.tags,
          value: DashboardSearchFilters.tags,
          label: "Tags",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Set",
          role: "ok",
          handler: (formResult: DashboardSearchFilters) => {
            setDashboardSerchFilter(formResult);
          },
        },
      ],
    });
  };

  const searchDashboardTemplates = async (input: string) => {
    if (input) {
      if (dashboardSearchFilter == DashboardSearchFilters.name) {
        const searchPromise = defaultIotaboardClient.getDashboardTemplates({
          dashboardTemplateName: input,
        });
        setSearchPromise(searchPromise);
        const response = await searchPromise;
        if (response.statusCode == 200) {
          const searchResult = response.data;
          if (searchResult) {
            const relevantResults = searchResult.filter(
              (dt) =>
                dt.type == DashboardTemplateType.device &&
                (dt.browsable || dt.templateName == searchKeyword)
            );
            setSearchResults(relevantResults);
          } else {
            setSearchResults(undefined);
          }
          setSearchPromise(undefined);
        } else {
          await dismissAlert();
          await presentAlert({
            subHeader: "An Error Occured",
            message: response.message,
            buttons: [
              {
                text: "See Details",
                handler: async () => {
                  await dismissAlert();
                  await presentAlert({
                    subHeader: "Problem Details",
                    message:
                      response.data?.toString() ?? "No details available",
                    buttons: [
                      {
                        text: "Close",
                        role: "cancel",
                      },
                    ],
                  });
                },
              },
              {
                text: "OK",
                role: "cancel",
              },
            ],
          });
        }
      }
    } else {
      setSearchResults(undefined);
    }
  };

  useEffect(() => {
    searchDashboardTemplates(searchKeyword);
  }, [searchKeyword]);

  const dashboardItemChosen = async (
    dashboardTemplateName: string,
    dashboardTemplateId: string
  ) => {
    await dismissAlert();
    await presentAlert({
      subHeader: "Let's give your new dashboard a name",
      message: `Try to keep it short but memorable and related to ${dashboardTemplateName}.`,
      inputs: [
        {
          type: "text",
          name: "name",
          label: "Name",
          placeholder: `My ${dashboardTemplateName} Dashboard`,
        },
      ],
      buttons: [
        {
          text: "Ok",
          handler: async (formResult) => {
            if (!formResult || !formResult["name"]) {
              await dismissToast();
              await presentToast("Name cannot be empty", 4000);
              return false;
            }

            await dismissLoading();
            await presentLoading({
              message: "Creating your dashboard",
              spinner: "circular",
            });
            const response = await defaultIotaboardClient.createDashboard({
              dashboardName: formResult["name"],
              dashboardTemplateId: dashboardTemplateId,
            });
            await dismissLoading();
            if (response.statusCode == 200) {
              const newDashboard = response.data;
              console.log("New Dashboard", newDashboard);
              await dismissAlert();
              await presentAlert({
                subHeader: "Dashboard created successfully",
                buttons: [
                  {
                    text: "Dismiss",
                    role: "cancel",
                  },
                  {
                    text: "Go To Dashboard",
                    handler: async () => {
                      history.push(
                        `/dashboards/${dashboardTemplateId}/${newDashboard?.dashboardId}`
                      );
                    },
                  },
                ],
              });
            } else {
              await dismissAlert();
              await presentAlert({
                subHeader: "An Error Occured",
                message: response.message,
                buttons: ["Ok"],
              });
            }
          },
          role: "ok",
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonContent className="">
        <div className="ion-margin">
          <div className="ion-margin-bottom" style={{ position: "sticky" }}>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol size="auto">
                  <BackButton
                    onClick={() => {
                      history.replace("/dashboards");
                    }}
                  ></BackButton>
                </IonCol>
                <IonCol>
                  <IonTitle>Add Dashboard</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol>
                <IonSearchbar
                  animated
                  showClearButton="always"
                  debounce={300}
                  onIonClear={() => {
                    setSearchKeyword("");
                  }}
                  onIonChange={(ev) => {
                    const input = ev.target.value;
                    if (input) {
                      setSearchKeyword(input);
                    }
                  }}
                  onIonInput={(ev) => {
                    if (!ev.target.value) {
                      setSearchKeyword("");
                    }
                  }}
                />
              </IonCol>
              <IonCol size="auto">
                <IonButton color="medium" onClick={chooseFilters}>
                  <IonIcon icon={filter} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="ion-padding">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">{searchPromise && <IonSpinner />}</IonCol>
            </IonRow>
          </IonGrid>
          {!searchResults && (
            <div className="ion-padding ion-text-center">
              {dashboardSearchFilter == DashboardSearchFilters.name ? (
                <IonNote>
                  Enter dashboard name in the search bar. Use the filter button
                  on the right to search by tags.
                </IonNote>
              ) : (
                <IonNote>
                  Enter tags separated by commas in the search bar. Use the
                  filter button on the right to search by name.
                </IonNote>
              )}
            </div>
          )}

          {searchResults && !searchResults.length && (
            <div className="ion-padding ion-text-center">
              <IonNote>
                No dashboard matching specified {dashboardSearchFilter}.
              </IonNote>
            </div>
          )}
          {searchResults && searchResults.length > 0 && (
            <IonList>
              {searchResults.map((dashboardTemplate) => (
                <IonItem
                  button
                  key={dashboardTemplate.templateName}
                  onClick={() =>
                    dashboardItemChosen(
                      dashboardTemplate.templateName,
                      dashboardTemplate.templateId
                    )
                  }
                >
                  <IonIcon icon={gridOutline} slot="start" />
                  <IonLabel>{dashboardTemplate.templateName}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
