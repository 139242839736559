export enum IotaboardNfcTagType {
  asset = "Asset",
}

export interface IotaboardNfcTagContent {
  type: IotaboardNfcTagType;
  id: string;
}

export interface IotaboardNfcTag {
  id: string;
  uid: string;
  content?: IotaboardNfcTagContent;
}
