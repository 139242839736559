export enum DashboardTemplateType {
  device = 1,
  asset = 2,
}

export interface DashboardTemplate {
  templateId: string;
  templateName: string;
  browsable: boolean;
  type: DashboardTemplateType;
  tags?: string[];
}
