import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonText,
  useIonToast,
} from "@ionic/react";

import {
  defaultNotificationCenter,
  NotificationCenterEntry,
} from "../../services/notification-center/NotificationCenter";

import "./popover-list-style.css";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";

export interface NotificationPopoverProps {
  dismiss: (data?: any, role?: string) => void;
}

export const NotificationPopover: React.FC<NotificationPopoverProps> = (
  props
) => {
  const [visibleEntries, setVisibleNotifEntries] = useState<
    NotificationCenterEntry[]
  >(defaultNotificationCenter.getNotificationEntries().slice(0, 9));

  const [presentToast, dismissToast] = useIonToast();

  const renderListItems = (entries: NotificationCenterEntry[]) => {
    return entries.map((e) => (
      <IonItem
        className="notif-item"
        key={e.id}
        button
        onClick={() => {
          defaultNotificationCenter.processNotification(e);
          props.dismiss(undefined, undefined);
        }}
      >
        <IonLabel className="ion-text-wrap">
          <IonText
            className="notif-list-title"
            color={e.extra?.color ?? "primary"}
          >
            {e.title}
          </IonText>
          <p className="notif-list-body">{e.body}</p>
          {e.extra?.ts && <p>{new Date(e.extra.ts).toLocaleString()}</p>}
        </IonLabel>
        <IonButton
          slot="end"
          className="btn-dismiss-notif"
          color="danger"
          fill="clear"
          onClick={(ev) => {
            ev.stopPropagation();
            defaultNotificationCenter.removeNotificationEntry(e);
            setVisibleNotifEntries(
              defaultNotificationCenter.getNotificationEntries()
            );
          }}
        >
          <IonIcon icon={closeOutline} />
        </IonButton>
      </IonItem>
    ));
  };

  const moreButton = () => {
    const allEntries = defaultNotificationCenter.getNotificationEntries();
    const countDifference = allEntries.length - visibleEntries.length;
    return (
      <IonItem
        button
        onClick={() => {
          const newItems = allEntries.slice(
            visibleEntries.length,
            visibleEntries.length + 10
          );

          if (!newItems.length) {
            dismissToast().then(() => {
              presentToast("End of list reached", 1000);
            });
          }

          setVisibleNotifEntries([...visibleEntries, ...newItems]);
        }}
      >
        <IonLabel color="primary">
          <h3>
            See {countDifference == 0 ? "all" : `${countDifference} more`}{" "}
            items...
          </h3>
        </IonLabel>
      </IonItem>
    );
  };

  const renderContent = () => {
    if (!visibleEntries || visibleEntries.length == 0) {
      return (
        <IonContent className="ion-padding">
          <IonNote>No notifications</IonNote>
        </IonContent>
      );
    } else {
      return (
        <IonContent>
          <IonList>
            <IonItem
              button
              onClick={() => {
                defaultNotificationCenter.clearNotificationEntries();
                setVisibleNotifEntries([]);
              }}
            >
              <IonLabel color="danger">
                <h3 className="ion-text-end">Clear all notifications</h3>
              </IonLabel>
            </IonItem>
            {renderListItems(visibleEntries)}
          </IonList>
          {moreButton()}
        </IonContent>
      );
    }
  };

  return renderContent();
};
