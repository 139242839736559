import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  useIonPopover,
} from "@ionic/react";
import { notifications } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  NotificationPopover,
  NotificationPopoverProps,
} from "./NotificationPopover";
import { defaultNotificationCenter } from "../../services/notification-center/NotificationCenter";

export interface MainAppBarProps {
  title: string;
}

export const MainAppBar: React.FC<MainAppBarProps> = (props) => {
  const [presentNotificationPopover, dismissNotificationPopover] =
    useIonPopover(NotificationPopover, {
      dismiss: (data, role) => dismissNotificationPopover(data, role),
    } as NotificationPopoverProps);
  
  const [hasNotif, setHasNotif] = useState<boolean>();

  useEffect(() => {
    const updateCallback = async () => {
      const _hasNotif = defaultNotificationCenter.getNotificationEntries().length > 0;
      setHasNotif(_hasNotif);
    }
    defaultNotificationCenter.addEventListener("notification-list-changed", updateCallback);
    updateCallback();
  
    return () => {
      defaultNotificationCenter.removeEventListener("notification-list-changed", updateCallback);
    }
  }, []);
  
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton
            onClick={async (ev) => {
              await presentNotificationPopover({
                event: ev as any,
              });
            }}
          >
            <IonIcon slot="icon-only" icon={notifications} color={hasNotif ? "warning" : ""} ></IonIcon>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default MainAppBar;
