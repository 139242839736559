import { ConfigurationModel } from "../configuration/configuration-model";

export const getHttpBaseUrlFromConfig = (
  configuration: ConfigurationModel,
  noAppendApiPrefix?: boolean
): string => {
  let httpBaseUrl;

  if (configuration.useHttps) {
    if (noAppendApiPrefix) {
      httpBaseUrl = `https://${configuration.serverHost}:${configuration.httpPort}`;
    } else {
      httpBaseUrl = `https://${configuration.serverHost}:${configuration.httpPort}/api`;
    }
  } else {
    if (noAppendApiPrefix) {
      httpBaseUrl = `http://${configuration.serverHost}:${configuration.httpPort}`;
    } else {
      httpBaseUrl = `http://${configuration.serverHost}:${configuration.httpPort}/api`;
    }
  }

  return httpBaseUrl;
};
