import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  ToggleChangeEventDetail,
  isPlatform,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import {
  addOutline,
  arrowUpOutline,
  closeOutline,
  codeOutline,
  codeSlashOutline,
  globeOutline,
  hardwareChip,
  optionsOutline,
  serverOutline,
} from "ionicons/icons";

import {
  IonToggleCustomEvent,
  loadingController,
} from "@ionic/core/components";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  loadConfiguration,
  saveConfiguration,
} from "../../services/configuration";
import { updateMenuEntries } from "../../components/Menu/Menu";
import {
  IotaboardChangeLog,
  IotaboardVersionInformation,
  versionInformation as thisVersionInformation,
} from "../../services/versioning";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { APIOperationResult } from "../../services/iotaboard-client/types/api-operation-result";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { blobToBase64 } from "../../utilities/conversion";
import { FileOpener } from "@capacitor-community/file-opener";
import { ChangeLogItem } from "../../components/ChangeLogItem";
import {
  NewVersionModal,
  NewVersionModalProps,
} from "../../components/NewVersionModal";

const maxTouches = 10;

export const AboutPage: React.FC = () => {
  const [buildVersionTouchCount, setBuildVersionTouchCount] = useState(0);
  const [presentToast, dismissToast] = useIonToast();
  const iotaboardServer = useMemo(() => loadConfiguration().serverHost, []);
  const [showDeveloperMode, setShowDeveloperMode] = useState<boolean>(
    loadConfiguration().developerMode ?? false
  );
  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [upstreamVersionInformation, setUpstreamVersionInformation] =
    useState<IotaboardVersionInformation>();
  const [presentNewVersionModal, dismissNewVersionModal] = useIonModal(
    NewVersionModal,
    {
      dismiss: (data, role) => dismissNewVersionModal(data, role),
      upstreamVersionInformation,
    } as NewVersionModalProps
  );
  const [updateAvailability, setUpdateAvailability] =
    useState<string>("Not checked");

  const [devDashboardList, setDevDashboardList] = useState<
    string[] | undefined
  >(loadConfiguration().developerDashboards);
  const history = useHistory();

  const buildClicked = async () => {
    if (buildVersionTouchCount < maxTouches) {
      setBuildVersionTouchCount(buildVersionTouchCount + 1);
    }

    if (buildVersionTouchCount >= 2 && buildVersionTouchCount < 10) {
      await dismissToast();
      await presentToast({
        message: `You are ${
          maxTouches - buildVersionTouchCount
        } steps away from being developer`,
        duration: 3000,
        icon: codeOutline,
        animated: false,
      });
    } else if (buildVersionTouchCount == maxTouches) {
      await dismissToast();
      await presentToast({
        message: "You are already a developer!",
        duration: 3000,
        icon: codeOutline,
        animated: false,
      });

      const configuration = loadConfiguration();
      if (!configuration.developerMode) {
        configuration.developerMode = true;
        saveConfiguration(configuration);
        updateMenuEntries();
        if (!showDeveloperMode) {
          setShowDeveloperMode(true);
        }
      }
    }
  };

  const disableDevMode = async (
    ev: IonToggleCustomEvent<ToggleChangeEventDetail>
  ) => {
    if (!ev.target.checked) {
      await dismissAlert();
      await presentAlert({
        subHeader: "Disable Developer Mode?",
        message: "You will lose access to developer features.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: async () => {
              ev.target.checked = true;
              return true;
            },
          },
          {
            text: "Disable",
            handler: async () => {
              const configuration = loadConfiguration();
              configuration.developerMode = false;
              saveConfiguration(configuration);
              setShowDeveloperMode(false);
              setBuildVersionTouchCount(0);
              updateMenuEntries();
              return true;
            },
          },
        ],
      });
    }
  };

  const canContinueWithPermissions = async () => {
    const permissions = await Filesystem.checkPermissions();
    if (permissions.publicStorage == "denied") {
      presentAlert({
        subHeader: "Iotaboard Can't Access Storage",
        message:
          "Iotaobard is denied to access your device storage. Please allow storage access for Iotaboard in the settings.",
        buttons: ["OK"],
      });
      return false;
    }
    if (permissions.publicStorage != "granted") {
      const requestResult = await Filesystem.requestPermissions();
      if (requestResult.publicStorage != "granted") {
        presentAlert({
          subHeader: "Permission Required",
          message:
            "Storage permission is required to store and update Iotaboard package",
          buttons: ["OK"],
        });
        return false;
      }
    }

    return true;
  };

  const downloadAndInstallAndroidApk = async () => {
    if (
      !upstreamVersionInformation ||
      !isPlatform("android") ||
      !isPlatform("capacitor")
    ) {
      return;
    }

    const canContinue = await canContinueWithPermissions();
    if (!canContinue) {
      return;
    }

    try {
      await Filesystem.mkdir({
        directory: Directory.Documents,
        path: "Iotaboard",
      });
    } catch (e) {
      if ((e as Error).message !== "Directory exists") {
        presentAlert({
          subHeader: "Cannot Access Directory",
          message: (e as Error).message,
        });
        return;
      }
    }

    const path = `Iotaboard/iotaboard-${upstreamVersionInformation.versionName}.apk`;

    let packageExists = false;

    try {
      await Filesystem.stat({
        directory: Directory.Documents,
        path,
      });
      packageExists = true;
    } catch (e) {
      if ((e as Error).message !== "File does not exist") {
        presentAlert({
          subHeader: "Unable to Save File",
          message: (e as Error).message,
          buttons: ["OK"],
        });
        return;
      }
    }

    if (packageExists) {
      setUpdateAvailability("Ready to install");
      const filePath = await Filesystem.getUri({
        directory: Directory.Documents,
        path,
      });
      await FileOpener.open({
        filePath: filePath.uri,
        contentType: "application/vnd.android.package-archive",
      });
      return;
    }

    const progressController = await loadingController.create({
      message: "Starting download..",
    });

    await progressController.present();

    const response = await defaultIotaboardClient.downloadLatestApk({
      onDownloadProgress: (ev) => {
        console.log(ev.progress);
        if (ev.progress == 1) {
          progressController.dismiss();
        }
        progressController.message = `${((ev.progress ?? 0) * 100).toFixed(
          1
        )}% downloaded`;
      },
    });

    await progressController.dismiss();
    if (response.statusCode !== 200) {
      await presentAlert({
        subHeader: "Download Failed",
        message: response.message,
        buttons: ["OK"],
      });

      return;
    }

    const base64Data = await blobToBase64(response.data!);

    const writeResult = await Filesystem.writeFile({
      directory: Directory.Documents,
      path,
      data: base64Data,
    });

    setUpdateAvailability("Ready to install");

    await FileOpener.open({
      filePath: writeResult.uri,
      contentType: "application/vnd.android.package-archive",
    });
  };

  const promptAndroidUpdate = () => {
    presentNewVersionModal({
      onDidDismiss: (ev) => {
        if (ev.detail.role == "update") {
          downloadAndInstallAndroidApk();
        }
      },
    });
  };

  const checkForUpdates = async () => {
    await presentLoading({
      message: "Checking for updates",
      backdropDismiss: true,
    });
    let response: APIOperationResult<IotaboardVersionInformation>;
    if (isPlatform("capacitor") && isPlatform("android")) {
      response = await defaultIotaboardClient.getUpstreamVersionInfo("android");
    } else {
      response = await defaultIotaboardClient.getUpstreamVersionInfo("web");
    }
    await dismissLoading();

    const _upstreamVersionInformation = response.data;
    if (!_upstreamVersionInformation) {
      return;
    }
    setUpstreamVersionInformation(_upstreamVersionInformation);
    if (
      _upstreamVersionInformation.versionCode >
      thisVersionInformation.versionCode
    ) {
      if (isPlatform("capacitor") && isPlatform("android")) {
        setUpdateAvailability("Update available.");
        promptAndroidUpdate();
      } else {
        setUpdateAvailability(
          "Update available. Clear cache and refresh to update."
        );
      }
      return;
    }
    if (
      _upstreamVersionInformation.versionCode <=
      thisVersionInformation.versionCode
    ) {
      setUpdateAvailability("Up to date");
    }
  };

  const addDevDashboard = async () => {
    await presentAlert({
      subHeader: "New Dashboard Entry",
      message: "The URL you entered will show up on Dashboards section",
      inputs: [
        {
          name: "url",
          placeholder: "http://localhost:9090/",
          label: "Dashboard URL",
          type: "url",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Add",
          handler: async (formResult) => {
            try {
              new URL(formResult["url"]);
              const configuration = loadConfiguration();
              configuration.developerDashboards = [
                ...(configuration.developerDashboards ?? []),
                formResult["url"],
              ];
              saveConfiguration(configuration);
              setDevDashboardList(configuration.developerDashboards);
            } catch (e) {
              await presentToast(
                "The URL you entered doesn't appear to be valid",
                4000
              );
              return false;
            }
          },
        },
      ],
    });
  };

  const removeDevDashboard = (removeUrl: string) => {
    const configuration = loadConfiguration();
    configuration.developerDashboards = devDashboardList?.filter(
      (dashboardUrl) => dashboardUrl != removeUrl
    );
    saveConfiguration(configuration);
    setDevDashboardList(configuration.developerDashboards);
    presentToast("Dashboard removed", 4000);
  };

  const setCacheTs = () => {
    const configuration = loadConfiguration();
    configuration.cacheTs = new Date().getTime();
    saveConfiguration(configuration);
    window.location.replace("/");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>About Iotaboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          Iotaboard is a product of Iotatechnology.
          <br />
          <br /> Iotaboard integrates and digitalizes multiple measurements for
          monitoring and controlling in one single platform.
        </div>
        <IonList>
          <IonItem>
            <IonLabel>Details</IonLabel>
          </IonItem>
          <IonItem button onClick={buildClicked}>
            <IonIcon icon={hardwareChip} slot="start" />
            <IonLabel>
              <h3>Build Version</h3>
              <p>v{thisVersionInformation.versionName}</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={checkForUpdates}>
            <IonIcon icon={arrowUpOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <h3>Check for updates</h3>
              <p>{updateAvailability}</p>
            </IonLabel>
          </IonItem>
          <IonItem
            button
            onClick={() => {
              history.push("/optimizations");
            }}
          >
            <IonIcon icon={optionsOutline} slot="start" />
            <IonLabel>
              <h3>Optimizations</h3>
              <p>Configure optimizations</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={setCacheTs}>
            <IonIcon icon={arrowUpOutline} slot="start" />
            <IonLabel>
              <h3>Update Modules</h3>
              <p>May use more data</p>
            </IonLabel>
          </IonItem>
        </IonList>
        {showDeveloperMode && (
          <>
            <IonList>
              <IonItem>
                <IonToggle
                  slot="end"
                  checked={showDeveloperMode}
                  onIonChange={disableDevMode}
                ></IonToggle>
                <IonLabel>Developer Mode</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon icon={serverOutline} slot="start" />
                <IonGrid>
                  <IonRow>Server</IonRow>
                  <IonRow>{iotaboardServer}</IonRow>
                </IonGrid>
              </IonItem>
              <IonItem>
                <IonIcon icon={globeOutline} slot="start" />
                <IonGrid>
                  <IonRow>Current Origin</IonRow>
                  <IonRow>{window.location.hostname}</IonRow>
                </IonGrid>
              </IonItem>
            </IonList>
            <IonList>
              <IonItem>
                <IonLabel>Development Dashboards</IonLabel>
                <IonButton shape="round" onClick={addDevDashboard}>
                  <IonIcon icon={addOutline} />
                </IonButton>
              </IonItem>
              {devDashboardList && devDashboardList.length > 0 ? (
                devDashboardList.map((url) => (
                  <IonItem key={url}>
                    <IonIcon icon={codeSlashOutline} slot="start" />
                    <IonText>{url}</IonText>
                    <IonButton
                      slot="end"
                      shape="round"
                      fill="clear"
                      color="danger"
                      onClick={() => removeDevDashboard(url)}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonItem>
                ))
              ) : (
                <IonItem>
                  <IonNote style={{ fontSize: "11pt" }}>
                    No saved development dashboard. Added dashboards will appear
                    on Dashboards section.
                  </IonNote>
                </IonItem>
              )}
            </IonList>
          </>
        )}
        <IonList>
          <IonListHeader>Change Log</IonListHeader>
          {thisVersionInformation.changeLogs.map((cl, index) => (
            <ChangeLogItem changeLog={cl} key={index}></ChangeLogItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
