// Startup service to setup stuff
// Has complex logic for initialization

import { ConfigurationModel } from "../configuration/configuration-model";
import { loadConfiguration, saveConfiguration } from "../configuration";
import { defaultIotaboardClient } from "../iotaboard-client";
import { defaultIotaboardRealtimeClient } from "../realtime";
import {
  RegisteredInterops,
  registerInterops,
} from "../remote-dashboard-interop";
import { defaultNotificationCenter } from "../notification-center/NotificationCenter";
import { globalHistory } from "../navigation/navigation";
import { startRemoteDashboardBackground } from "../remote-dashboard-background";
import { enableBackgroundMode, moveToBackground } from "../background-mode";
import { App } from "@capacitor/app";

export const runStartupAndGetStartingRoute = async (): Promise<string> => {
  await enableBackgroundMode();
  const interops: RegisteredInterops = {
    defaultIotaboardClient: defaultIotaboardClient,
    defaultIotaboardRealtimeClient: defaultIotaboardRealtimeClient,
    loadConfiguration: loadConfiguration,
    saveConfiguration: saveConfiguration,
    defaultNotificationCenter: defaultNotificationCenter,
    navigationHistory: globalHistory,
  };
  registerInterops(interops);

  App.addListener("backButton", (data) => {
    if (!data.canGoBack) {
      moveToBackground();
    }
  });

  const configuration: ConfigurationModel = loadConfiguration();

  if (!configuration.tokenCache) {
    return "/login";
  }

  const initResult = await defaultIotaboardClient.initializeWithToken(
    configuration
  );
  if (initResult.statusCode != 200) {
    return "/login";
  }
  defaultIotaboardRealtimeClient.initialize(configuration);
  await defaultIotaboardRealtimeClient.startRealtimeDataSubscription();
  await startRemoteDashboardBackground(interops);
  return "/dashboards";
};
