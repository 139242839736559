import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  useIonAlert,
  useIonModal,
} from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { globalHistory } from "../../services/navigation/navigation";
import { useLocation } from "react-router";
import { radioOutline } from "ionicons/icons";
import { ScanModal } from "../../components/ScanNfcModal";
import { NFC, NdefEvent } from "@awesome-cordova-plugins/nfc";
import { bytesToHex } from "../../utilities/conversion";
import {
  TagInspectionModal,
  TagInspectionModalProps,
} from "./TagInspectionModal";

export const TagsPage: React.FC = () => {
  const [presentAlert, dismissAlert] = useIonAlert();
  const location = useLocation();

  const [tagInspectionModalProps, setTagInspectionModalProps] =
    useState<TagInspectionModalProps>();

  const [presentScanModal, dismissScanModal] = useIonModal(ScanModal);
  const [presentTagInspectionModal, dismissTagIspectionModal] = useIonModal(
    TagInspectionModal,
    tagInspectionModalProps
  );

  useEffect(() => {
    const token = defaultIotaboardClient.token;
    if (!token) {
      presentAlert({
        subHeader: "Cannot Perform Action",
        message: "You are not authorized to access this page.",
        buttons: ["OK"],
        onDidDismiss: () => {
          if (location.key) {
            globalHistory.goBack();
          } else {
            globalHistory.replace("/");
          }
        },
      });
      return;
    }
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
  });

  const registerNfc = async () => {
    try {
      await NFC.enabled();
      const nfcEvents = await NFC.addNdefListener();
      const subscription = nfcEvents.subscribe(async (ndef: NdefEvent) => {
        const uidBytes = ndef.tag.id;
        const uidStr = bytesToHex(uidBytes, "-");
        console.log(uidStr);
        const response = await defaultIotaboardClient.registerNfcTag(uidStr);
        if (response.statusCode == 200) {
          await presentAlert({
            subHeader: "Success",
            message: response.message,
            buttons: ["OK"],
          });
        } else {
          await presentAlert({
            subHeader: "Registration Failed",
            message: response.message,
            buttons: ["OK"],
          });
        }
        await dismissScanModal();
      });

      await presentScanModal({
        cssClass: "popup-dialog",
        onDidDismiss: () => {
          subscription.unsubscribe();
        },
      });
    } catch (e) {
      if (e == "NO_NFC") {
        presentAlert({
          subHeader: "NFC Unavailable",
          message: "NFC is not available on this device.",
          buttons: ["OK"],
        });
      } else if (e == "NFC_DISABLED") {
        presentAlert({
          subHeader: "NFC Disabled",
          message: "Please enable NFC to perform this operation.",
          buttons: [
            {
              text: "Dismiss",
            },
            {
              text: "Open Settings",
              handler: async () => {
                await NFC.showSettings();
              },
            },
          ],
        });
      }
    }
  };

  const inspectTag = async () => {
    try {
      await NFC.enabled();
      const nfcEvents = await NFC.addNdefListener();
      const subscription = nfcEvents.subscribe(async (ndef: NdefEvent) => {
        console.log(ndef);
        const uidBytes = ndef.tag.id;
        const uidStr = bytesToHex(uidBytes, "-");
        const response = await defaultIotaboardClient.getTagContent(uidStr);
        setTagInspectionModalProps({
          ndefEvent: ndef,
          response: response,
          dismiss: () => dismissTagIspectionModal()
        });
        await dismissScanModal();
        presentTagInspectionModal();
      });

      await presentScanModal({
        initialBreakpoint: 0.6,
        onDidDismiss: () => {
          subscription.unsubscribe();
        },
      });
    } catch (e) {
      if (e == "NO_NFC") {
        presentAlert({
          subHeader: "NFC Unavailable",
          message: "NFC is not available on this device.",
          buttons: ["OK"],
        });
      } else if (e == "NFC_DISABLED") {
        presentAlert({
          subHeader: "NFC Disabled",
          message: "Please enable NFC to perform this operation.",
          buttons: [
            {
              text: "Dismiss",
            },
            {
              text: "Open Settings",
              handler: async () => {
                await NFC.showSettings();
              },
            },
          ],
        });
      }
    }
  };

  return (
    <IonPage>
      <MainAppBar title="Tags Management" />
      <IonContent>
        <IonList>
          <IonListHeader>Actions</IonListHeader>
          <IonItem button onClick={() => registerNfc()}>
            <IonIcon icon={radioOutline} slot="start" />
            <IonLabel>
              <h3>Register Tag</h3>
              <p>Make Iotaboard recognize an NFC tag</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => inspectTag()}>
            <IonIcon icon={radioOutline} slot="start" />
            <IonLabel>
              <h3>Inspect Tag</h3>
              <p>See data on server and its NDEF content</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
