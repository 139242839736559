import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonRow,
  IonText,
} from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import { useState } from "react";

export interface ChangePasswordActionResult {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordPopoverProps {
  dismiss: (data?: ChangePasswordActionResult, role?: string) => void;
}

export const ChangePasswordPopover: React.FC<ChangePasswordPopoverProps> = (
  props
) => {
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newRepeatedPassword, setNewRepeatedPassword] = useState<string>();

  const shouldDisableButton = () => {
    if (
      currentPassword &&
      currentPassword.length >= 8 &&
      newPassword &&
      newPassword.length >= 8 &&
      newRepeatedPassword &&
      newRepeatedPassword.length >= 8 &&
      newPassword == newRepeatedPassword
    ) {
      return false;
    }
    return true;
  };
  return (
    <IonContent>
      <IonListHeader className="ion-margin-start">
        <IonText color="primary"><b>Change Password</b></IonText>
      </IonListHeader>
      <IonItem fill="solid" className="ion-margin">
        <IonLabel position="stacked">Current Password</IonLabel>
        <IonInput
          type="password"
          onIonChange={(ev) => {
            const input = ev.target.value as string | undefined;
            setCurrentPassword(input);
          }}
        ></IonInput>
      </IonItem>
      <IonItem fill="solid" className="ion-margin">
        <IonLabel position="stacked">New Password</IonLabel>
        <IonInput
          type="password"
          onIonChange={(ev) => {
            const input = ev.target.value as string | undefined;
            setNewPassword(input);
          }}
        ></IonInput>
      </IonItem>
      <IonItem fill="solid" className="ion-margin">
        <IonLabel position="stacked">Repeat New Password</IonLabel>
        <IonInput
          type="password"
          onIonChange={(ev) => {
            const input = ev.target.value as string | undefined;
            setNewRepeatedPassword(input);
          }}
        ></IonInput>
      </IonItem>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="auto">
            <IonIcon
              icon={warningOutline}
              style={{ fontSize: "16pt" }}
              color="warning"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonText style={{ fontSize: "9pt" }} color="warning">
              Changing password will log you out. Changes will take effect on
              other devices after next login.
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton
        expand="block"
        className="ion-margin-top"
        disabled={shouldDisableButton()}
        onClick={() => {
          const _currentPassword = currentPassword;
          const _newPassowrd = newPassword;
          if (_currentPassword && _newPassowrd) {
            props.dismiss(
              {
                currentPassword: _currentPassword,
                newPassword: _newPassowrd,
              },
              "change"
            );
          }
        }}
      >
        Change Password
      </IonButton>
    </IonContent>
  );
};
