import { IonContent, IonNote, IonPage, IonSpinner } from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { useEffect, useState } from "react";
import { ConfigurationModel } from "../../services/configuration/configuration-model";
import { loadConfiguration } from "../../services/configuration";
import { useParams } from "react-router";
import { DashboardRemoteComponent } from "../../components/DashboardRemoteComponent/DashboardRemoteComponent";
import { getHttpBaseUrlFromConfig } from "../../services/iotaboard-client/utilities";
import { DashboardTemplate } from "../../services/iotaboard-client/types/dashboard-template";
import { defaultIotaboardClient } from "../../services/iotaboard-client";

export const MaintenanceDashboardViewer: React.FC = () => {
  const [configuration] = useState<ConfigurationModel>(loadConfiguration());

  const params = useParams<{
    assetId: string;
  }>();

  const createDashboardTemplateUrl = (
    dashboardTemplateId: string,
    configuration: ConfigurationModel
  ) => {
    return `${getHttpBaseUrlFromConfig(
      configuration
    )}/dashboard-templates/${dashboardTemplateId}/main.js`;
  };

  const [dashboardTemplate, setDashboardTemplate] = useState<
    DashboardTemplate | undefined | null
  >();

  const loadMatchingDashboardTemplateInfo = async () => {
    const permissionResponse =
      await defaultIotaboardClient.getThisUserAssetAccessPermission(
        params.assetId
      );
    if (permissionResponse.statusCode == 200) {
      const permission = permissionResponse.data!;
      const type = permission.asset.type;

      const dashboardTemplateResponse =
        await defaultIotaboardClient.getMaintenanceDashboardTemplate(type);
      if (dashboardTemplateResponse.statusCode == 200) {
        const _dashboardTemplate = dashboardTemplateResponse.data;
        setDashboardTemplate(_dashboardTemplate);
      } else {
        setDashboardTemplate(null);
      }
    }
  };

  useEffect(() => {
    loadMatchingDashboardTemplateInfo();
  }, []);

  if (dashboardTemplate) {
    return (
      <IonPage>
        <MainAppBar title={dashboardTemplate.templateName} />
        <IonContent>
          <DashboardRemoteComponent
            url={createDashboardTemplateUrl(
              dashboardTemplate.templateId,
              configuration
            )}
            assetId={params.assetId}
            fallback={
              <div className="container">
                <IonSpinner />
              </div>
            }
          />
        </IonContent>
      </IonPage>
    );
  } else if (dashboardTemplate === undefined) {
    return (
      <IonPage>
        <MainAppBar title="Loading Maintenance Dashboard" />
        <IonContent>
          <div className="container">
            <IonSpinner />
          </div>
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <MainAppBar title="Maintenance" />
      <IonContent>
        <IonContent className="ion-padding">
          <div className="container">
            <IonNote>This asset type is not supported for maintenance</IonNote>
          </div>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};
