const decoder = new TextDecoder();

export interface RawWifiList {
  [ssid: string]: number[];
}

export interface WifiItem {
  ssid: string;
  rssi: number;
  authRequired: boolean;
}

export const wifiListFromRaw = (rawList: RawWifiList): WifiItem[] => {
  const list: WifiItem[] = [];
  for (const ssid in rawList) {
    list.push({
      ssid: ssid,
      rssi: rawList[ssid][0],
      authRequired: rawList[ssid][1] == 1,
    });
  }
  // sort by strongest rssi
  list.sort((a, b) => b.rssi - a.rssi);
  return list;
};

export const rawWifiListFromArrayBuffer = (
  buffer: ArrayBuffer
): RawWifiList => {
  const jsonString = decoder.decode(buffer);
  try {
    const rawWifiList: RawWifiList = JSON.parse(jsonString);
    return rawWifiList;
  } catch (err) {
    return {};
  }
};

export const objectToNumbers = (obj: any) => {
  const numbers = new TextEncoder().encode(JSON.stringify(obj));
  return numbers;
};

export const stringFromBuffer = (buffer: ArrayBuffer): string => {
  const string = decoder.decode(buffer);
  return string;
};
