import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import {
  alertCircleOutline,
  batteryChargingOutline,
  optionsOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import {
  checkPermissionsRequested,
  disableBatteryOptimizations,
  disableWebViewOptimizations,
  isIgnoringBatteryOptimizations,
  requestForegroundPermission,
} from "../../services/background-mode";

export const OptimizationsPage: React.FC = () => {
  const [foregroundPermissionAllowed, setForegroundPermissionAllowed] =
    useState<boolean>();

  const [batteryOptimizationDisabled, setBatteryOptimizationDisabled] =
    useState<boolean>();

  const getPermissionStatuses = async () => {
    const _overlayPermissionEnabled = await checkPermissionsRequested();
    setForegroundPermissionAllowed(_overlayPermissionEnabled);
    const _batteryOptimizationEnabled = await isIgnoringBatteryOptimizations();
    setBatteryOptimizationDisabled(_batteryOptimizationEnabled);
    console.log("Battery XCKSADLKASD enabled", _batteryOptimizationEnabled);
  };

  useEffect(() => {
    getPermissionStatuses();

    const interval = setInterval(() => {
      getPermissionStatuses();
    }, 500);

    return clearInterval(interval);
  }, []);

  return (
    <IonPage>
      <MainAppBar title="Optimizations" />
      <IonContent className="ion-padding">
        <div>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol>
                <IonText>
                  Optimizations help to keep Iotaboard running in background.
                  This is to ensure you receive realtime notifications and have
                  better experience, in expense of more power consumption.
                </IonText>
              </IonCol>
              <IonCol size="auto" className="ion-margin-end">
                <IonIcon icon={optionsOutline} size="large" color="primary" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  You may want to enable optimizations when Iotaboard
                  notifications or alerts are high priority.
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="auto">
                <IonIcon icon={alertCircleOutline} color="warning" />
              </IonCol>
              <IonCol>
                <IonText color="warning" style={{ fontSize: "10pt" }}>
                  This doesn't prevent Iotaboard getting terminated, either from
                  settings, OS, or recent apps list.
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonList className="ion-margin-top">
          <IonItem
            button
            onClick={() => {
              disableBatteryOptimizations();
            }}
          >
            <IonIcon
              slot="start"
              icon={batteryChargingOutline}
              color="primary"
            />
            <IonLabel>
              <h3>Disable Battery Optimizations</h3>
              {batteryOptimizationDisabled ? (
                <p>Battery optimization disabled</p>
              ) : (
                <p>Tap to open settings. Set no restrictions.</p>
              )}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            onClick={() => {
              disableWebViewOptimizations();
            }}
          >
            <IonIcon
              slot="start"
              icon={batteryChargingOutline}
              color="primary"
            />
            <IonLabel>
              <h3>Disable Webview Optimizations</h3>
              <p>Tap to disable</p>
            </IonLabel>
          </IonItem>
          <IonItem
            button
            onClick={async () => {
              await requestForegroundPermission();
            }}
          >
            <IonIcon
              slot="start"
              icon={batteryChargingOutline}
              color="primary"
            />
            <IonLabel>
              <h3>Allow Overlay Permission</h3>
              {foregroundPermissionAllowed ? (
                <p>Enabled</p>
              ) : (
                <p>Tap to open settings. Find Iotaboard and allow.</p>
              )}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
