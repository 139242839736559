import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  IonText,
  useIonAlert,
} from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { radioOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { NFC, NdefEvent } from "@awesome-cordova-plugins/nfc";
import { NfcStatus } from "../../services/nfc/types/nfc-status";
import { NfcDataMimeType as IotaboardNfcDataMimeType } from "../../services/nfc/types/constants";
import {
  IotaboardNfcTagContent,
  IotaboardNfcTagType,
} from "../../services/nfc/types/iotaboard-nfc-data";
import { globalHistory } from "../../services/navigation/navigation";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { bytesToHex } from "../../utilities/conversion";

export const MaintenancePage: React.FC = () => {
  const [nfcStatus, setNfcStatus] = useState<NfcStatus>();
  const [presentAlert, dismissAlert] = useIonAlert();

  const getNfcStatus = async () => {
    try {
      const enabled = await NFC.enabled();
      setNfcStatus(enabled);
      return enabled;
    } catch (e) {
      setNfcStatus(e as NfcStatus);
      return e;
    }
  };

  useEffect(() => {
    getNfcStatus();
    const interval = setInterval(() => {
      getNfcStatus();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (nfcStatus == NfcStatus.NFC_OK) {
      const nfcEvents = NFC.addMimeTypeListener(
        IotaboardNfcDataMimeType,
        () => {}
      );
      const subscription = nfcEvents.subscribe(async (ndef: NdefEvent) => {
        const uidBytes = ndef.tag.id;
        const uidStr = bytesToHex(uidBytes, "-");
        const payload = ndef.tag.ndefMessage.find(
          (record) =>
            String.fromCharCode(...record.type) == IotaboardNfcDataMimeType
        )?.payload;

        if (!payload) {
          presentAlert({
            subHeader: "Invalid Tag",
            message: "The approached tag is not Iotaboard NFC tag.",
            buttons: ["OK"],
          });
          return;
        }

        const payloadStr = String.fromCharCode(...payload);
        const content: IotaboardNfcTagContent = JSON.parse(payloadStr);
        const response = await defaultIotaboardClient.getTagContent(uidStr);
        if (
          !content.type ||
          content.type != IotaboardNfcTagType.asset ||
          !content.id
        ) {
          presentAlert({
            subHeader: "Invalid Tag",
            message: "The approached tag does not belong to an asset.",
            buttons: ["OK"],
          });
          return;
        }
        if (response.statusCode != 200) {
          presentAlert({
            subHeader: "Invalid Tag",
            message: response.message,
            buttons: ["OK"],
          });
          return;
        }
        if (
          response.data &&
          response.data.type == IotaboardNfcTagType.asset &&
          response.data.id == content.id
        ) {
          globalHistory.push(`/maintenance/${content.id}`);
          return;
        }
        presentAlert({
          subHeader: "Card is Corrupted",
          message: "Card and server data mismatch",
        });
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [nfcStatus]);

  const gettingStatusDisplay = () => {
    return (
      <div>
        <IonSpinner />
        <IonText color={"primary"}>Getting NFC availability</IonText>
      </div>
    );
  };

  const nfcStatusDisplay = () => {
    if (nfcStatus == NfcStatus.NFC_OK) {
      return (
        <>
          <div className="ion-margin-bottom">
            <IonIcon
              icon={radioOutline}
              color="primary"
              style={{ fontSize: "64px" }}
            />
          </div>
          <div className="ion-padding-bottom">
            <IonText color={"primary"}>Approach an asset NFC tag</IonText>
          </div>
        </>
      );
    } else if (nfcStatus == NfcStatus.NFC_DISABLED) {
      return (
        <>
          <div className="ion-margin-bottom">
            <IonIcon icon={radioOutline} style={{ fontSize: "64px" }} />
          </div>
          <div className="ion-padding-bottom">
            <IonText>NFC must be enabled to scan Iotaboard NFC tags.</IonText>
          </div>
          <div className="ion-padding-bottom">
            <IonButton onClick={() => NFC.showSettings()}>
              Open Settings
            </IonButton>
          </div>
        </>
      );
    } else if (
      nfcStatus == NfcStatus.NO_NFC ||
      nfcStatus == NfcStatus.cordova_not_available
    ) {
      return (
        <>
          <div className="ion-margin-bottom">
            <IonIcon
              icon={radioOutline}
              color="medium"
              style={{ fontSize: "64px" }}
            />
          </div>
          <div className="ion-padding-bottom">
            <IonText color={"medium"}>
              NFC is required to perform this operation but your device does not
              support NFC.
            </IonText>
          </div>
        </>
      );
    }
  };

  return (
    <IonPage>
      <MainAppBar title="Maintenance" />
      <IonContent className="ion-padding">
        <div className="container ion-text-center ion-padding">
          {!nfcStatus ? gettingStatusDisplay() : nfcStatusDisplay()}
        </div>
      </IonContent>
    </IonPage>
  );
};
