import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonPopover,
} from "@ionic/react";
import {
  addSharp,
  hardwareChip,
  hardwareChipOutline,
  settingsSharp,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { Device } from "../../services/iotaboard-client/types/device";
import {
  DeviceOptionsPopover,
  DeviceOptionsPopoverProps,
} from "./DeviceOptionsPopover";

export const DevicesPage: React.FC = () => {
  const history = useHistory();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  useEffect(() => {
    getDevices();
  }, []);

  const [deviceList, setDeviceList] = useState<Device[]>();
  const [activeOptionsDevice, setActiveOptionsDevice] = useState<Device>();

  const getDevices = async () => {
    console.log("Getting device");
    const response = await defaultIotaboardClient.getUserDeviceList();
    if (response.statusCode == 200) {
      setDeviceList(response.data);
    }
  };

  const showUpdateDeviceInfoModal = async (device: Device) => {
    await presentAlert({
      subHeader: "Update Device Information",
      message: "This will help you to identify your devices.",
      inputs: [
        {
          label: "Device Name",
          name: "deviceName",
          type: "text",
          placeholder: "My Smart Device",
          value: device.userDefinedName,
        },
        {
          label: "Device Group",
          name: "groupName",
          type: "text",
          placeholder: "Site X",
          value: device.groupName,
        },
      ],
      buttons: [
        {
          text: "Later",
          role: "cancel",
        },
        {
          text: "OK",
          role: "ok",
          handler: async (result) => {
            const deviceName = result["deviceName"];
            const groupName = result["groupName"];
            await presentLoading({
              message: "Updating device information",
            });
            const response =
              await defaultIotaboardClient.updateDeviceInformation(
                device.deviceId,
                {
                  deviceName: deviceName,
                  groupName: groupName,
                }
              );
            await dismissLoading();
            if (response.statusCode != 200) {
              await dismissAlert();
              await presentAlert({
                subHeader: "Failed Updating Information",
                buttons: ["OK"],
              });
            }
            await getDevices();
          },
        },
      ],
      onDidDismiss: () => {
        // props.swiperInstance?.slideTo(0);
      },
    });
  };

  const [presentOptionsPopover, dismissOptionsPopover] = useIonPopover(
    DeviceOptionsPopover,
    {
      dismiss: (data, role) => {
        dismissOptionsPopover();
        if (role == "edit") {
          showUpdateDeviceInfoModal(data as Device);
        }
      },
      device: activeOptionsDevice,
    } as DeviceOptionsPopoverProps
  );

  const showDeviceOptions = (ev: any, device: Device) => {
    ev.stopPropagation();
    setActiveOptionsDevice(device);
    console.log(device);
    presentOptionsPopover({
      event: ev,
    });
  };

  return (
    <IonPage>
      <MainAppBar title="Devices" />
      <IonContent>
        <IonList style={{ marginBottom: "36px" }}>
          <IonListHeader>Your Devices</IonListHeader>
          {deviceList?.map((d) => (
            <IonItem key={d.deviceId}>
              <IonIcon
                icon={hardwareChipOutline}
                slot="start"
                color="primary"
              />
              <IonLabel>
                <h3>{d.userDefinedName ?? "Unnamed Device"}</h3>
                <p>
                  {d.deviceModel.modelName} v{d.deviceModel.versionString}
                </p>
              </IonLabel>
              <IonButton
                slot="end"
                fill="clear"
                onClick={(ev) => {
                  showDeviceOptions(ev, d);
                }}
                style={{ height: "100%" }}
              >
                <IonIcon icon={settingsSharp} style={{padding: "12px"}} />
              </IonButton>
            </IonItem>
          ))}
        </IonList>

        <IonFab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
          className="ion-margin"
        >
          <IonFabButton
            onClick={() => {
              history.push("/pair-device");
            }}
          >
            <IonIcon icon={addSharp} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};
