import {
  IonButton,
  IonIcon,
  IonText,
  IonTitle,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import { getBlePairingService } from "../../../services/ble-pairing";
import { CrossSlideInterfacing } from "../cross-slide-interfacing";

export const GettingStarted: React.FC<CrossSlideInterfacing> = (props) => {
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert, dismissAlert] = useIonAlert();

  return (
    <div>
      <div className="ion-margin">
        <IonIcon
          color="primary"
          icon={searchOutline}
          style={{ fontSize: "80px" }}
        />
      </div>
      <div className="ion-margin">
        <IonTitle color="primary">Before We Start</IonTitle>
      </div>
      <div className="ion-margin">
        <IonText>
          Iotaboard devices discovery requires Bluetooth and related
          permissions.
        </IonText>
      </div>
      <div className="ion-margin ion-padding-top">
        <IonButton
          onClick={async () => {
            await presentLoading({
              message: "Starting Bluetooth Services",
              spinner: "circles",
            });
            const blePairingService = getBlePairingService();
            const initResult = await blePairingService.initialize();
            await dismissLoading();
            if (initResult.success) {
              props.swiperInstance?.slideNext();
            } else {
              await presentAlert({
                subHeader: "Cannot Start Bluetooth",
                message: `Make sure required permissions are granted and your device supports bluetooth.`,
                buttons: [
                  {
                    text: "Details",
                    handler: async () => {
                      await dismissAlert();
                      await presentAlert({
                        subHeader: "Failure Details",
                        message: initResult.error?.message,
                        buttons: ["Ok"],
                      });
                    },
                  },
                  {
                    text: "Go To Settings",
                    handler: () => {
                      blePairingService.openAppSettings();
                    },
                  },
                ],
              });
            }
          }}
        >
          Grant and Continue
        </IonButton>
      </div>
    </div>
  );
};
