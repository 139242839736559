import jwtDecode from "jwt-decode";
import { loadConfiguration } from "../services/configuration";
import { AuthToken } from "../services/iotaboard-client/types/auth-token";
import { defaultIotaboardClient } from "../services/iotaboard-client";

export function byteToHex(byte: number) {
  return ("" + (byte & 0xff).toString(16)).padStart(2, "0");
}

export function bytesToHex(bytes: number[], separator?: string) {
  return Array.from(bytes, (byte) => byteToHex(byte)).join(separator ?? "");
}

export function getDecodedJwt(source: "configuration" | "iotaboard-client") {
  if (source == "configuration") {
    const configuration = loadConfiguration();
    if (configuration && configuration.tokenCache) {
      const decodedToken = jwtDecode<AuthToken>(configuration.tokenCache);
      return decodedToken;
    }
  } else if (source == "iotaboard-client") {
    if (defaultIotaboardClient.token) {
      const decodedToken = jwtDecode<AuthToken>(defaultIotaboardClient.token);
      return decodedToken;
    }
  }
}

export const dataUrlToBlob = async (dataUrl: string) => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return blob;
};

export const extensionFromDataUrl = (dataUrl: string) => {
  return dataUrl.substring(
    dataUrl.indexOf("/") + 1,
    dataUrl.indexOf(";base64")
  );
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};
