import { IonContent, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { radioOutline, shieldSharp } from "ionicons/icons";

export interface AssetOptionsPopoverProps {
  onDidDismiss: (data?: any, role?: string) => void | Promise<any>
}

export const AssetOptionsPopover: React.FC<AssetOptionsPopoverProps> = (props) => {
  return (
    <IonContent>
      <IonList>
        <IonItem button onClick={() => {
          props.onDidDismiss(undefined, "create-nfc");
        }}>
          <IonIcon icon={radioOutline} slot="start" />
          <IonLabel>Create NFC Tag</IonLabel>
        </IonItem>
        <IonItem button onClick={() => {
          props.onDidDismiss(undefined, "manage-permissions");
        }}>
          <IonIcon icon={shieldSharp} slot="start" />
          <IonLabel>Manage Permissions</IonLabel>
        </IonItem>
      </IonList>
    </IonContent>
  );
};
