import {
  ActionSheetButton,
  IonActionSheet,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonModal,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Dashboard } from "../../services/iotaboard-client/types/dashboard";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { arrowBack, person, personRemoveSharp, qrCode } from "ionicons/icons";
import {
  ScanQrModal,
  ScanQrModalProps,
} from "../AssetPermissionsPage/scan-qr-modal";
import { AssetUserInfo } from "../../services/iotaboard-client/types/asset-user-info";

export interface DashboardShareModalProps {
  dismiss: (data?: any, role?: string) => void | Promise<any>;
  dashboard?: Dashboard;
}

export const DashboardShareModal: React.FC<DashboardShareModalProps> = (
  props
) => {
  const [dashboard, setDashboard] = useState(props.dashboard);
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const [isScanningQr, setIsScanningQr] = useState<boolean>();
  const [searchUsername, setSearchUsername] = useState<string>();
  const [searcheduser, setSearchedUser] = useState<AssetUserInfo>();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState<boolean>(false);
  const [actionSheetButtons, setActionSheetButtons] = useState<
    ActionSheetButton<any>[]
  >([]);
  const [presentLoading, dismissLoading] = useIonLoading();

  const [presentScanQrModal, dismissScanQrModal] = useIonModal(ScanQrModal, {
    onResult: (data, role) => dismissScanQrModal(data, role),
  } as ScanQrModalProps);

  const [presentAlert, dismissAlert] = useIonAlert();

  const scanQrCode = async () => {
    setIsScanningQr(true);
    presentScanQrModal({
      showBackdrop: false,
      onDidDismiss: (ev) => {
        if (ev.detail.role == "username" && ev.detail.data) {
          const username = ev.detail.data as string;
          console.log(searchBarRef.current);
          if (searchBarRef.current) {
            searchBarRef.current.value = username;
          }
          // setSearchUsername(username);
        }
        setIsScanningQr(false);
      },
    });
  };

  const searchUser = async () => {
    if (searchUsername) {
      const response = await defaultIotaboardClient.getUserInfo(searchUsername);
      if (response.statusCode == 400 || response.statusCode == 500) {
        presentAlert({
          subHeader: "Cannot Search User",
          message: response.message,
          buttons: ["OK"],
        });
      }

      setSearchedUser(response.data);
    }
  };

  useEffect(() => {
    searchUser();
  }, [searchUsername]);

  const getDashboard = async () => {
    if (dashboard) {
      const response = await defaultIotaboardClient.getDashboard(
        dashboard.dashboardId
      );
      setDashboard(response.data);
    }
  };

  const unassignUser = async (username: string) => {
    await presentLoading();
    await defaultIotaboardClient.unassignUserFromDashboard(
      props.dashboard!.dashboardId,
      [username]
    );
    await getDashboard();
    await dismissLoading();
  };

  const promptUnassignUser = (username: string) => {
    setActionSheetButtons([
      {
        text: "Remove",
        icon: personRemoveSharp,
        handler: async () => {
          await unassignUser(username);
        },
      },
    ]);
    setIsActionSheetOpen(true);
  };

  const promptAssignUser = (username: string) => {
    setActionSheetButtons([
      {
        text: "Make Admin",
        role: "make-admin",
        handler: async () => {
          await presentLoading();
          await unassignUser(username);
          await defaultIotaboardClient.assignUserToDashboard({
            dashboardId: props.dashboard!.dashboardId,
            adminUsernames: [username],
          });
          await getDashboard();
          await dismissLoading();
        },
      },
      {
        text: "Make Guest",
        role: "make-guest",
        handler: async () => {
          await presentLoading();
          unassignUser(username);
          await defaultIotaboardClient.assignUserToDashboard({
            dashboardId: props.dashboard!.dashboardId,
            guestUsernames: [username],
          });
          await getDashboard();
          await dismissLoading();
        },
      },
    ]);
    setIsActionSheetOpen(true);
  };

  if (isScanningQr) {
    return <IonContent></IonContent>;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => props.dismiss()}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Share Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonSearchbar
                placeholder="Enter username"
                ref={searchBarRef}
                debounce={200}
                onIonInput={(ev) => {
                  const searchKeyword = ev.detail.value as string | undefined;
                  setSearchUsername(searchKeyword);
                }}
              />
            </IonCol>
            <IonCol size="auto">
              <IonButton
                color="medium"
                onClick={() => {
                  scanQrCode();
                }}
              >
                <IonIcon icon={qrCode} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {props.dashboard && searcheduser && (
          <IonCard>
            <IonItem
              button
              onClick={() => {
                promptAssignUser(searcheduser.username);
              }}
            >
              <IonIcon icon={person} slot="start" />
              <IonLabel>
                <h3>
                  {searcheduser.firstName} {searcheduser.lastName}
                </h3>
                <p>{searcheduser.username}</p>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        <IonList>
          <IonListHeader>Owner</IonListHeader>
          <IonItem>
            <IonIcon slot="start" icon={person}></IonIcon>
            <IonLabel>
              <h3>{dashboard?.ownerUsername}</h3>
            </IonLabel>
          </IonItem>
        </IonList>
        <IonList>
          <IonListHeader>Admins</IonListHeader>
          {dashboard?.adminUsernames?.length ? (
            <IonList>
              {dashboard.adminUsernames.map((u) => (
                <IonItem
                  button
                  key={u}
                  onClick={() => {
                    promptUnassignUser(u);
                  }}
                >
                  <IonIcon icon={person} slot="start" />
                  <IonLabel>
                    <h3>{u}</h3>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          ) : (
            <div className="ion-padding">
              <IonNote>No admins</IonNote>
            </div>
          )}
        </IonList>
        <IonList>
          <IonListHeader>Guests</IonListHeader>
          {dashboard?.guestUsernames?.length ? (
            <IonList>
              {dashboard.guestUsernames.map((u) => (
                <IonItem
                  key={u}
                  button
                  onClick={() => {
                    promptUnassignUser(u);
                  }}
                >
                  <IonIcon icon={person} slot="start" />
                  <IonLabel>
                    <h3>{u}</h3>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          ) : (
            <div className="ion-padding">
              <IonNote>No guests</IonNote>
            </div>
          )}
        </IonList>
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header="Actions"
          onDidDismiss={() => setIsActionSheetOpen(false)}
          buttons={actionSheetButtons}
        />
      </IonContent>
    </IonPage>
  );
};
