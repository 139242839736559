import { IonContent, IonPage, IonSpinner, useIonAlert } from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { useEffect, useState } from "react";
import { ConfigurationModel } from "../../services/configuration/configuration-model";
import { loadConfiguration } from "../../services/configuration";
import { useLocation, useParams } from "react-router";
import { DashboardRemoteComponent } from "../../components/DashboardRemoteComponent/DashboardRemoteComponent";
import { getHttpBaseUrlFromConfig } from "../../services/iotaboard-client/utilities";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { APIOperationResult } from "../../services/iotaboard-client/types/api-operation-result";
import { Dashboard } from "../../services/iotaboard-client/types/dashboard";

export const RemoteDashboardViewer: React.FC = () => {
  const [configuration, setConfiguration] = useState<ConfigurationModel>(
    loadConfiguration()
  );

  const params = useParams<{
    dashboardId: string;
    dashboardTemplateId: string;
  }>();

  const createDashboardTemplateUrl = (
    dashboardTemplateId: string,
    configuration: ConfigurationModel
  ) => {
    return `${getHttpBaseUrlFromConfig(
      configuration
    )}/dashboard-templates/${dashboardTemplateId}/main.js`;
  };

  const [getDashboardPromise, setGetDashboardPromise] =
    useState<Promise<APIOperationResult<Dashboard>>>();
  const [dashboard, setDashboard] = useState<Dashboard>();

  const [presentAlert, dismissAlert] = useIonAlert();

  const getDashboard = async (dashboardId: string) => {
    const promise = defaultIotaboardClient.getDashboard(dashboardId);
    setGetDashboardPromise(promise);
    const response = await promise;
    if (response.statusCode == 200) {
      setDashboard(response.data);
    } else {
      await dismissAlert();
      await presentAlert({
        subHeader: "Something Went Wrong",
        message: response.message,
        buttons: ["Ok"],
      });
    }

    setGetDashboardPromise(undefined);
  };

  const location = useLocation();

  // Effect to reload dashboard when location changes
  useEffect(() => {
    if (
      location.pathname ==
      `/dashboards/${params.dashboardTemplateId}/${params.dashboardId}`
    ) {
      getDashboard(params.dashboardId);
    }
  }, [location.pathname]);

  return (
    <IonPage>
      <MainAppBar title={dashboard?.dashboardName ?? "Loading Dashboard"} />
      <IonContent>
        {dashboard ? (
          <DashboardRemoteComponent
            url={createDashboardTemplateUrl(
              params.dashboardTemplateId,
              configuration
            )}
            fallback={
              <div className="container">
                <IonSpinner />
              </div>
            }
            dashboardId={dashboard.dashboardId}
            dashboardTemplateId={dashboard.dashboardTemplateId}
          />
        ) : (
          getDashboardPromise && (
            <div className="container">
              <IonSpinner />
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
