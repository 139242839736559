import { IonPage } from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { useParams } from "react-router";
import { DashboardRemoteComponent } from "../../components/DashboardRemoteComponent/DashboardRemoteComponent";

export const DevelopmentRemoteDashboardViewer: React.FC = () => {
  const params = useParams<{ url: string }>();
  console.log("In dev", params, decodeURIComponent(params.url));

  return (
    <IonPage>
      <MainAppBar title="Development Dashboard" />
      <DashboardRemoteComponent url={decodeURIComponent(params.url)} />
    </IonPage>
  );
};
