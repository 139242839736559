import {
  RegisteredInterops,
  RemoteDashboardBackgroundInitFn,
} from "../remote-dashboard-interop";
import createLoadRemoteModule, {
  createRequires,
} from "@paciolan/remote-module-loader";

const dependencies = {
  react: require("react"),
  "@ionic/react": require("@ionic/react"),
  "@ionic/react-router": require("@ionic/react-router"),
  "@microsoft/signalr": require("@microsoft/signalr"),
  "date-fns": require("date-fns"),
  ionicons: require("ionicons"),
  "react-dom": require("react-dom"),
  "react-router": require("react-router"),
  "react-router-dom": require("react-router-dom"),
};

const cleanupFunctions: Function[] = [];

export const startRemoteDashboardBackground = async (
  interops: RegisteredInterops
) => {
  const requires = createRequires(dependencies);
  const fetcher = async (dashboardTemplateId: string) => {
    const response =
      await interops.defaultIotaboardClient.getDashboardTemplateModule(
        dashboardTemplateId
      );
    if (response.statusCode == 200) {
      return response.data;
    } else {
      console.log("Unable to fetch dashboard module");
    }
  };

  const loadRemoteModule = createLoadRemoteModule({
    requires: requires,
    fetcher: fetcher,
  });

  const dashboardResponse =
    await interops.defaultIotaboardClient.getDashboards();
  if (dashboardResponse.statusCode == 200) {
    const dashboards = dashboardResponse.data;
    if (dashboards && dashboards.length > 0) {
      dashboards.forEach(async (dashboard) => {
        const remoteModule = await loadRemoteModule(
          dashboard.dashboardTemplateId
        );
        const initFunction = remoteModule.backgroundInit as
          | RemoteDashboardBackgroundInitFn
          | undefined;
        initFunction?.({
          interops,
          dashboardId: dashboard.dashboardId,
        });
      });
    }
  }
  const permissionsResponse = await interops.defaultIotaboardClient.getAssets();
  if (permissionsResponse.statusCode == 200) {
    const permissions = permissionsResponse.data;
    if (permissions && permissions.length > 0) {
      permissions.forEach(async (permission) => {
        const dashboardTemplate =
          await interops.defaultIotaboardClient.getMaintenanceDashboardTemplate(
            permission.asset.type
          );
        if (dashboardTemplate.statusCode !== 200 || !dashboardTemplate.data) {
          return;
        }
        const remoteModule = await loadRemoteModule(
          dashboardTemplate.data.templateId
        );

        const initFunction = remoteModule.backgroundInit as
          | RemoteDashboardBackgroundInitFn
          | undefined;
        const cleanupFunction = initFunction?.({
          interops,
          assetId: permission.assetId,
        });
        if (cleanupFunction && typeof cleanupFunction == "function") {
          cleanupFunctions.push(cleanupFunction);
        }
      });
    }
  }
};

export const stopRemoteDashboardBackground = async () => {
  const cleanupPromises = cleanupFunctions.map(async (cf) => {
    const invokeResult = cf();
    if (invokeResult instanceof Promise) {
      await invokeResult;
    }
  });

  const promises = Promise.all(cleanupPromises);
  await promises;
  cleanupFunctions.length = 0;
};
