import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  useIonAlert,
  useIonLoading,
  useIonModal,
} from "@ionic/react";

import "./LoginPage.css";
import "../Page.css";

import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { useHistory } from "react-router";
import { ConfigurationModel } from "../../services/configuration/configuration-model";
import {
  loadConfiguration,
  saveConfiguration,
} from "../../services/configuration";
import { settingsSharp } from "ionicons/icons";
import { defaultIotaboardRealtimeClient } from "../../services/realtime";
import { startRemoteDashboardBackground } from "../../services/remote-dashboard-background";
import { getInterops } from "../../services/remote-dashboard-interop";
import { NFC, NdefEvent } from "@awesome-cordova-plugins/nfc";
import { ScanModal } from "../../components/ScanNfcModal";
import { SmartLoginMimeType } from "../../services/nfc/types/constants";
import { IotaboardSmartLoginCredentials } from "../../services/nfc/types/iotaboard-smartlogin-credentials";
import { isPlatform } from "@ionic/core";

export const LoginPage: React.FC = () => {
  const history = useHistory();

  const [presentAlert] = useIonAlert();
  const [presentLoading, dissmissLoading] = useIonLoading();
  const [presentScanNfcModal, dismissScanNfcModal] = useIonModal(ScanModal);

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassowrd] = useState<boolean>(false);

  const login = async (username: string, password: string) => {
    // Username and password shouldn't be empty
    if (!username || !password) return;

    const configuration: ConfigurationModel = loadConfiguration(); //get the server url

    presentLoading({
      message: "Logging in",
    });

    const result = await defaultIotaboardClient.initializeWithCredentials(
      {
        username: username,
        password: password,
      },
      configuration
    );

    if (result.statusCode == 200) {
      configuration.tokenCache = defaultIotaboardClient.token;
      saveConfiguration(configuration);
      defaultIotaboardRealtimeClient.initialize(configuration);
      defaultIotaboardRealtimeClient.startRealtimeDataSubscription();
      await startRemoteDashboardBackground(getInterops());

      history.replace("/dashboards");
    } else if (result.statusCode == 400 || result.statusCode == 401) {
      dissmissLoading();
      presentAlert({
        subHeader: "Invalid Username or Password",
        message:
          "The username or password you entered does not match any registered user. Please try again.",
        buttons: ["OK"],
      });
      return;
    } else {
      dissmissLoading();
      presentAlert({
        subHeader: "Unable to Sign In",
        message: `${result.message}
          Please check your internet connection or application configuration and try again.`,
        buttons: ["OK"],
      });
      return;
    }
    dissmissLoading();
  };

  const smartLogin = async () => {
    try {
      await NFC.enabled();
      const listener = NFC.addMimeTypeListener(SmartLoginMimeType);

      const subscription = listener.subscribe(async (ndef: NdefEvent) => {
        const payload = ndef.tag.ndefMessage.find(
          (record) => String.fromCharCode(...record.type) == SmartLoginMimeType
        )?.payload;

        // payload is in base64
        if (payload) {
          const base64String = String.fromCharCode(...payload);
          const decoded = atob(base64String);
          const smartLoginCredentials: IotaboardSmartLoginCredentials =
            JSON.parse(decoded);
          setUsername(smartLoginCredentials.u);
          setPassword(smartLoginCredentials.p);
          dismissScanNfcModal();
          await login(smartLoginCredentials.u, smartLoginCredentials.p);
        }
      });
      presentScanNfcModal({
        cssClass: "popup-dialog",
        onDidDismiss: () => {
          subscription.unsubscribe();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="container">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCard className="login-card">
                <IonCardHeader>
                  <IonGrid>
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      <IonCol size="2" />
                      <IonCol>
                        <IonCardTitle className="ion-margin-top">
                          <strong>IOTABOARD</strong>
                        </IonCardTitle>
                        <IonCardSubtitle className="">Sign In</IonCardSubtitle>
                      </IonCol>
                      <IonCol size="2">
                        <IonButton fill="clear" routerLink="/settings">
                          <IonIcon icon={settingsSharp} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem lines="inset">
                    <IonLabel position="floating">Email or Username</IonLabel>
                    <IonInput
                      type="email"
                      onIonInput={(ev) => {
                        setUsername(ev.target.value?.toString());
                      }}
                      autofocus
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="inset">
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      type={`${showPassword ? "text" : "password"}`}
                      onIonInput={(ev) => {
                        setPassword(ev.target.value?.toString());
                      }}
                      onKeyDown={(ev) => {
                        if (ev.key == "Enter" && username && password) {
                          login(username, password);
                        }
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      onIonChange={(ev) => {
                        setShowPassowrd(ev.target.checked);
                      }}
                    />
                    <IonLabel>Show password</IonLabel>
                  </IonItem>
                  {isPlatform("capacitor") && (
                    <IonGrid>
                      <IonRow className="ion-justify-content-end">
                        <IonButton
                          fill="clear"
                          color="warning"
                          onClick={() => smartLogin()}
                        >
                          Smart Login
                        </IonButton>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonButton
                    expand="block"
                    color="primary"
                    disabled={!(!!username && !!password)}
                    onClick={() => {
                      if (username && password) {
                        login(username, password);
                      }
                    }}
                  >
                    Log In
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};
