import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonPopover,
} from "@ionic/react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { UserInfo } from "../../services/iotaboard-client/types/user-info";
import { useEffect, useState } from "react";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import {
  ChangePasswordActionResult,
  ChangePasswordPopover,
  ChangePasswordPopoverProps,
} from "./ChangePasswordPopver";
import {
  loadConfiguration,
  saveConfiguration,
} from "../../services/configuration";
import { useHistory } from "react-router";
import { qrCode } from "ionicons/icons";
import { QrCodePopover, QrCodePopoverProps } from "./qrcode-popover";
import { UpdateUserInfoOptions } from "../../services/iotaboard-client/types/update-user-info-options";

export const AccountPage: React.FC = () => {
  const [user, setUser] = useState<UserInfo>();
  const [loading, setLoading] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const getUserInfo = async () => {
    setLoading(true);
    const response = await defaultIotaboardClient.getThisUserInfo();
    if (response.statusCode == 200) {
      setUser(response.data);
    } else {
      setErrorMessage(response.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("Getting account info");
    getUserInfo();
  }, []);

  const [passwordPopoverUsername, setPasswordPopoverUsername] = useState("");

  const [presentPasswordPopover, dismissPasswordPopover] = useIonPopover(
    ChangePasswordPopover,
    {
      dismiss: (data, role) => {
        dismissPasswordPopover(data, role);
      },
      username: passwordPopoverUsername,
    } as ChangePasswordPopoverProps
  );

  const [presentQrCodePopover, dismissQrCodePopover] = useIonPopover(
    QrCodePopover,
    {
      dismiss: (data, role) => dismissPasswordPopover(data, role),
      username: user?.username,
    } as QrCodePopoverProps
  );

  const [presentLoading, dismissLoading] = useIonLoading();
  const history = useHistory();
  const [presentAlert, dismissAlert] = useIonAlert();

  const updateUserInfo = async () => {
    if (!user) {
      return;
    }
    const newUserInfo: UpdateUserInfoOptions = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
    };

    await presentLoading("Updating Account Information");
    const response = await defaultIotaboardClient.updateUserInformation(
      newUserInfo
    );
    await dismissLoading();

    if (response.statusCode != 200) {
      await presentAlert({
        subHeader: "Failed Updating User Information",
        message: response.message,
        buttons: ["OK"],
      });
      return;
    }
    await dismissAlert();
    await presentAlert({
      subHeader: "Account Information Updated",
      message: "Changes will take effect after your next login.",
      buttons: ["OK"],
    });
  };

  const promptUpdateUserInfo = async () => {
    await presentAlert({
      subHeader: "Update Account Information?",
      message: "Are you sure to update your account information?",
      buttons: [
        {
          text: "Update",
          handler: async () => {
            await updateUserInfo();
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });
  };

  const updateUserInfoEnabled = () => user?.firstName && user.phoneNumber;

  return (
    <IonPage>
      <MainAppBar title="Account" />
      <IonContent className="ion-padding">
        {loading ? (
          <div className="container">
            <IonSpinner />
          </div>
        ) : errorMessage ? (
          <div className="container">
            <IonText color="danger">{errorMessage}</IonText>
          </div>
        ) : (
          user && (
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCard className="login-card">
                  <IonCardContent>
                    <IonListHeader>Login Credentials</IonListHeader>
                    <IonGrid className="ion-no-padding">
                      <IonRow className="ion-align-items-center">
                        <IonCol>
                          <IonItem>
                            <IonLabel position="stacked">Username</IonLabel>
                            <IonInput
                              placeholder="johndoe"
                              value={user.username}
                              readonly
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                        <IonCol size="auto">
                          <IonButton
                            className="ion-margin-top"
                            onClick={() => {
                              presentQrCodePopover();
                            }}
                          >
                            <IonIcon icon={qrCode} />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonItem>
                      <IonLabel position="stacked">Email</IonLabel>
                      <IonInput
                        placeholder="johndoe@somemail.com"
                        value={user.email}
                        readonly
                      ></IonInput>
                    </IonItem>
                    <IonButton
                      color="danger"
                      expand="block"
                      onClick={() => {
                        setPasswordPopoverUsername(user.username);
                        presentPasswordPopover({
                          onDidDismiss: async (ev) => {
                            if (
                              ev.detail.data &&
                              ev.detail.role &&
                              ev.detail.role == "change"
                            ) {
                              const result: ChangePasswordActionResult =
                                ev.detail.data;
                              await presentLoading({
                                message: "Changing and logging out",
                              });
                              const response =
                                await defaultIotaboardClient.changePassword({
                                  currentPassword: result.currentPassword,
                                  newPassword: result.newPassword,
                                });
                              await dismissLoading();
                              if (response.statusCode == 200) {
                                // Sign out
                                const configuration = loadConfiguration();
                                configuration.tokenCache = undefined;
                                saveConfiguration(configuration);
                                history.replace("/login");
                              } else {
                                await presentAlert({
                                  subHeader: "Failed Changing Password",
                                  message: response.message,
                                  buttons: ["OK"],
                                });
                              }
                            }
                          },
                        });
                      }}
                    >
                      Change Password
                    </IonButton>
                    <IonListHeader className="ion-margin-top">
                      User Details
                    </IonListHeader>
                    <IonItem>
                      <IonLabel position="stacked">Phone Number</IonLabel>
                      <IonInput
                        placeholder="6285123456789"
                        value={user.phoneNumber}
                        onIonInput={(ev) => {
                          setUser({
                            ...user,
                            phoneNumber: ev.detail.value ?? "",
                          });
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">First Name</IonLabel>
                      <IonInput
                        placeholder="John"
                        value={user.firstName}
                        onIonInput={(ev) => {
                          setUser({
                            ...user,
                            firstName: ev.detail.value ?? "",
                          });
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">Last Name</IonLabel>
                      <IonInput
                        placeholder="Doe"
                        value={user.lastName}
                        onIonInput={(ev) => {
                          setUser({
                            ...user,
                            lastName: ev.detail.value ?? "",
                          });
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonButton
                      expand="block"
                      disabled={!updateUserInfoEnabled()}
                      onClick={async () => {
                        await promptUpdateUserInfo();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </IonGrid>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
