import { isPlatform } from "@ionic/react";
import { BackgroundMode } from "capacitor-plugin-background-mode";

export const checkPermissionsRequested = async (): Promise<boolean> => {
  if (isPlatform("capacitor")) {
    const permission = await BackgroundMode.checkForegroundPermission();
    return permission.enabled;
  } else {
    return true;
  }
};

export const requestForegroundPermission = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.requestForegroundPermission();
  }
};

export const disableBatteryOptimizations = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.disableBatteryOptimizations();
  }
};

export const isIgnoringBatteryOptimizations = async () => {
  if (isPlatform("capacitor")) {
    const result = await BackgroundMode.isIgnoringBatteryOptimizations();
    return result.isIgnoring;
  }
  return true;
};

export const disableWebViewOptimizations = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.disableWebViewOptimizations();
  }
};

export const moveToBackground = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.moveToBackground();
  }
}

export const moveToForeground = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.moveToForeground();
  }
}

export const enableBackgroundMode = async () => {
  if (isPlatform("capacitor")) {
    await BackgroundMode.setSettings({
      title: "Iotaboard",
      text: "Iotaboard is running",
    });

    await BackgroundMode.enable();
  }
};
