import * as SignalR from "@microsoft/signalr";
import { ConfigurationModel } from "../configuration/configuration-model";
import { DeviceLatestRecords } from "../iotaboard-client/types/device-latest-records";

import { getHttpBaseUrlFromConfig } from "../iotaboard-client/utilities";

export type RealtimeEventNames = "new-telemetry" | "new-status";
export type RealtimeConnectionStates = "started" | "closed";
export const connections = new Map<string, SignalR.HubConnection>();
export const getConnection = (
  url: string,
  token: string
): SignalR.HubConnection => {
  if (connections.has(url)) {
    return connections.get(url)!;
  }

  const connection = new SignalR.HubConnectionBuilder()
    .withUrl(url, {
      skipNegotiation: true,
      transport: SignalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => 2000,
    })
    .build();

  connections.set(url, connection);

  return connection;
};

export class IotaboardRealtimeClient {
  private baseUrl?: string;
  private token?: string;

  initialize(configuration: ConfigurationModel) {
    if (!configuration || !configuration.tokenCache) {
      throw new Error("Token in configuration is required to initialize");
    }
    this.baseUrl = getHttpBaseUrlFromConfig(configuration);
    this.token = configuration.tokenCache;
  }

  async ensureConnection(connection: SignalR.HubConnection) {
    if (
      connection.state != SignalR.HubConnectionState.Connected &&
      connection.state != SignalR.HubConnectionState.Connecting &&
      connection.state != SignalR.HubConnectionState.Reconnecting
    ) {
      try {
        await connection.start();
        this.connectionStateChangeHandlers.forEach((handler) => {
          handler(connection.state);
        });
      } catch (e) {
        console.error(e);
        this.connectionStateChangeHandlers.forEach((handler) => {
          handler(connection.state);
        });
      }
    }
  }

  async getDevicesHubConnection(
    autoStart?: boolean
  ): Promise<SignalR.HubConnection> {
    if (!this.baseUrl || !this.token) {
      throw new Error("Iotaboard realtime client has not been initialized");
    }
    const connection = getConnection(
      `${this.baseUrl}/hubs/devices`,
      this.token
    );
    if (autoStart) {
      await this.ensureConnection(connection);
    }
    return connection;
  }

  async waitDeviceConnect(deviceId: string) {
    const connection = await this.getDevicesHubConnection(true);

    const deviceConnected = await connection.invoke<boolean>(
      "WaitForDeviceConnect",
      deviceId,
      60
    );
    return deviceConnected;
  }

  private newTelemetryHandlers: ((
    records: DeviceLatestRecords
  ) => void | Promise<any>)[] = [];
  private newStatusHandlers: ((
    records: DeviceLatestRecords
  ) => void | Promise<any>)[] = [];
  private connectionStateChangeHandlers: ((
    state: SignalR.HubConnectionState
  ) => void | Promise<any>)[] = [];

  async startRealtimeDataSubscription() {
    const connection = await this.getDevicesHubConnection();

    connection.on("NewTelemetry", (records: DeviceLatestRecords) => {
      this.newTelemetryHandlers.forEach((handler) => {
        try {
          const result = handler(records);
          if (result instanceof Promise) {
            result.catch((e) => console.error(e));
          }
        } catch (e) {
          console.error(e);
        }
      });
    });

    connection.on("NewStatus", (records: DeviceLatestRecords) => {
      this.newStatusHandlers.forEach((handler) => {
        try {
          const result = handler(records);
          if (result instanceof Promise) {
            result.catch((e) => console.error(e));
          }
        } catch (e) {
          console.error(e);
        }
      });
    });

    const connectionStateChangeCallback = async () => {
      this.connectionStateChangeHandlers.forEach((handler) => {
        handler(connection.state);
      });
    };
    connection.onclose(connectionStateChangeCallback);
    connection.onreconnected(connectionStateChangeCallback);
    connection.onreconnecting(connectionStateChangeCallback);

    this.ensureConnection(connection);
  }

  addDevicesEventListener(
    eventName: RealtimeEventNames,
    handler: (records: DeviceLatestRecords) => Promise<void>
  ) {
    if (eventName === "new-telemetry") {
      this.newTelemetryHandlers.push(handler);
    } else if (eventName == "new-status") {
      this.newStatusHandlers.push(handler);
    }
  }

  removeDevicesEventListener(
    eventName: RealtimeEventNames,
    handler: (records: DeviceLatestRecords) => void | Promise<any>
  ) {
    if (eventName === "new-telemetry") {
      this.newTelemetryHandlers = this.newTelemetryHandlers.filter(
        (h) => h != handler
      );
    } else if (eventName == "new-status") {
      this.newStatusHandlers = this.newStatusHandlers.filter(
        (h) => h != handler
      );
    }
  }

  addConnectionStateChangeEventListener(
    handler: (state: SignalR.HubConnectionState) => void | Promise<any>
  ) {
    this.connectionStateChangeHandlers.push(handler);
  }

  removeConnectionStateChangeEventListener(
    handler: (state: SignalR.HubConnectionState) => void | Promise<any>
  ) {
    this.connectionStateChangeHandlers =
      this.connectionStateChangeHandlers.filter((h) => h != handler);
  }

  async closeRealtimeConnections() {
    connections.forEach((connection, key) =>
      connection.stop().then(() => connections.delete(key))
    );
  }

  async getConnectionState() {
    const connection = await this.getDevicesHubConnection();
    return connection.state;
  }
}

export const defaultIotaboardRealtimeClient = new IotaboardRealtimeClient();
