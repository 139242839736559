import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect } from "react";
import "./qr-styles.css";
import { arrowBackSharp } from "ionicons/icons";

export interface ScanQrModalProps {
  onResult: (data?: any, role?: string) => void | Promise<any>;
}

export const ScanQrModal: React.FC<ScanQrModalProps> = (props) => {
  const stopQrScan = async () => {
    BarcodeScanner.stopScan();
    BarcodeScanner.showBackground();
    document.querySelector("body")!.classList.remove("scanner-active");
  };

  const startQrScan = async () => {
    await BarcodeScanner.checkPermission({ force: true });
    document.querySelector("body")!.classList.add("scanner-active");
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan();

    if (result.hasContent) {
      props.onResult(result.content, "username");
    }

    await stopQrScan();
  };

  useEffect(() => {
    startQrScan();
    return () => {
      stopQrScan();
    };
  }, []);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton>
              <IonIcon className="ion-padding" icon={arrowBackSharp} />
            </IonButton>
          </IonButtons>
          <IonTitle>Scan QR Code</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container ion-text-center">
          <div className="ion-margin-bottom ion-padding-bottom">
            <IonText>Point to account QR Code</IonText>
          </div>
          <div className="qr-box">
            <div className="scan-line"></div>
          </div>
        </div>
      </IonContent>
    </>
  );
};
