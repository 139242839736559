import React, { useState } from "react";
import {
  IotaboardVersionInformation,
  versionInformation,
} from "../../services/versioning";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonListHeader,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ChangeLogItem } from "../ChangeLogItem";
import { arrowBackSharp } from "ionicons/icons";

export interface NewVersionModalProps {
  upstreamVersionInformation: IotaboardVersionInformation;
  dismiss: (data?: any, role?: string) => void | Promise<void>;
}

export const NewVersionModal: React.FC<NewVersionModalProps> = (props) => {
  const [understood, setUnderstood] = useState<boolean>();
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => props.dismiss()}>
              <IonIcon icon={arrowBackSharp} />
            </IonButton>
            <IonTitle>New Version Available</IonTitle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h3>
          <IonText color="primary">
            Iotaboard v{props.upstreamVersionInformation.versionName}
          </IonText>
        </h3>
        <div>
          <IonNote>Current version: {versionInformation.versionName}</IonNote>
        </div>

        <div className="ion-margin-top">
          <IonList>
            <IonListHeader>What's New</IonListHeader>
            {props.upstreamVersionInformation.changeLogs.map((cl, index) => (
              <ChangeLogItem changeLog={cl} key={index} />
            ))}
          </IonList>
        </div>
        <h4 className="ion-margin-top">
          <IonText color="primary">Proceed with Caution</IonText>
        </h4>
        <p>
          While the update may contain fixes and improvements over current
          version, it may bring breaking changes or incompatibilties to existing
          devices or dashboards. Please read change logs carefully and consult
          vendor if you are unsure whether upgrade is necessary.
        </p>
        <p>
          Iotatechnology provides support for the update but takes no
          responsibility of damages or issues caused by updates.
        </p>
        <div className="ion-margin-vertical">
          <IonCheckbox
            labelPlacement="end"
            className="ion-text-wrap"
            onIonChange={(ev) => {
              setUnderstood(ev.detail.checked);
            }}
          >
            I understand the risks.
          </IonCheckbox>
        </div>
        <div className="ion-margin-top ion-padding-top">
          <IonButton
            disabled={!understood}
            expand="full"
            onClick={() => props.dismiss(undefined, "update")}
          >
            Update
          </IonButton>
        </div>
      </IonContent>
    </>
  );
};
