import React, { useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { PairDeviceSwipePage } from "./pages/PairDeviceSwipePage/PairDeviceSwipePage";
import { DevicesPage } from "./pages/DevicesPage/DevicesPage";
import { DashboardsPage } from "./pages/DashboardsPage/DashboardsPage";
import { RemoteDashboardViewer } from "./pages/RemoteDashboardViewer/RemoteDashboardViewer";
import { DevelopmentRemoteDashboardViewer } from "./pages/RemoteDashboardViewer/DevelopmentRemoteDashboardViewer";
import { AddDashboardPage } from "./pages/AddDashboardPage/AddDashboardPage";
import { AccountPage } from "./pages/AccountPage";
import { ExperimentsPage } from "./pages/ExperimentsPage/ExperimentsPage";
import { globalHistory } from "./services/navigation/navigation";
import { OptimizationsPage } from "./pages/OptimizationsPage";
import { AssetsPage } from "./pages/AssetsPage";
import { MaintenanceDashboardViewer } from "./pages/MaintenanceDashboardViewer";
import { MaintenancePage } from "./pages/MaintenancePage";
import { AssetPermissionsPage } from "./pages/AssetPermissionsPage";
import { TagsPage } from "./pages/NfcTags";
import { defaultIotaboardRealtimeClient } from "./services/realtime";

setupIonicReact();

interface AppProps {
  startingRoute?: string;
}

const App: React.FC<AppProps> = (props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (globalHistory.location.pathname != "/login") {
        defaultIotaboardRealtimeClient
          .getDevicesHubConnection()
          .then((connection) => {
            defaultIotaboardRealtimeClient.ensureConnection(connection);
          });
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    }
  }, []);
  return (
    <IonApp>
      <IonReactRouter history={globalHistory}>
        <IonSplitPane
          contentId="main"
          style={{
            "--side-max-width": "264px",
          }}
        >
          <Menu id="menu" />
          <IonRouterOutlet id="main">
            <Route
              exact
              path="/"
              render={() => {
                return props.startingRoute ? (
                  <Redirect to={props.startingRoute} />
                ) : (
                  <Redirect to="/login" />
                );
              }}
            ></Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/dashboard-add">
              <AddDashboardPage />
            </Route>
            <Route exact path="/dashboards">
              <DashboardsPage />
            </Route>
            <Route exact path="/dashboards-dev/:url">
              <DevelopmentRemoteDashboardViewer />
            </Route>
            <Route exact path="/dashboards/:dashboardTemplateId/:dashboardId">
              <RemoteDashboardViewer />
            </Route>
            <Route exact path="/maintenance">
              <MaintenancePage />
            </Route>
            <Route exact path="/maintenance/:assetId">
              <MaintenanceDashboardViewer />
            </Route>
            <Route exact path="/settings">
              <SettingsPage />
            </Route>
            <Route exact path="/assets">
              <AssetsPage />
            </Route>
            <Route exact path="/assets-permissions/:assetId">
              <AssetPermissionsPage />
            </Route>
            <Route exact path="/devices">
              <DevicesPage />
            </Route>
            <Route exact path="/pair-device">
              <PairDeviceSwipePage />
            </Route>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route exact path="/account">
              <AccountPage />
            </Route>
            <Route exact path="/optimizations">
              <OptimizationsPage />
            </Route>
            <Route exact path="/test">
              <ExperimentsPage />
            </Route>
            <Route exact path="/nfc-tags">
              <TagsPage />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
