import { IonContent, IonIcon, IonItem, IonList, IonText } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import { Device } from "../../services/iotaboard-client/types/device";

export interface DeviceOptionsPopoverProps {
  device: Device;
  dismiss: (data?: any, role?: string) => void;
}

export const DeviceOptionsPopover: React.FC<DeviceOptionsPopoverProps> = (
  props
) => {
  return (
    <IonContent>
      <IonList>
        <IonItem
          button
          onClick={() => {
            props.dismiss(props.device, "edit");
          }}
        >
          <IonIcon slot="start" icon={pencilOutline} />
          <IonText>Edit</IonText>
        </IonItem>

        {/* TODO: Add share device, unclaim device */}
      </IonList>
    </IonContent>
  );
};
