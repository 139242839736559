import {
  IonIcon,
  IonItem,
  IonList,
  IonSpinner,
  IonText,
  IonTitle,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { checkmarkSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import {
  Credentials,
  DeviceConnectResults,
  getBlePairingService,
} from "../../../services/ble-pairing";
import { defaultIotaboardClient } from "../../../services/iotaboard-client";
import { defaultIotaboardRealtimeClient } from "../../../services/realtime";
import { delay } from "../../../utilities/delay";
import { CrossSlideInterfacing } from "../cross-slide-interfacing";
import { PreviousSlideButton } from "../PreviousSlideButton/PreviousSlideButton";
import { useHistory } from "react-router";
import { Device } from "../../../services/iotaboard-client/types/device";

export interface Props {
  credentials?: Credentials;
  successCallback: () => void;
}

export const ConnectProgressSlide: React.FC<CrossSlideInterfacing & Props> = (
  props
) => {
  // Effect to take action when slide is entered
  useEffect(() => {
    if (props.currentIndex == 4) {
      console.log("Starting Connect Progress");
      setProgress(0);
      sendWifiCredentials();
    }
  }, [props.currentIndex]);

  const [progress, setProgress] = useState(0);

  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const history = useHistory();

  const sendWifiCredentials = async () => {
    const blePairingService = getBlePairingService();
    const connectedBleDeviceId = blePairingService.getConnectedBleDeviceId();
    const connectedIotaboardDeviceId =
      blePairingService.getConnectedIotaboardDeviceId();
    const credentials = props.credentials;
    if (!(connectedBleDeviceId && connectedIotaboardDeviceId && credentials))
      return;

    const deviceConnectPromise =
      defaultIotaboardRealtimeClient.waitDeviceConnect(
        connectedIotaboardDeviceId
      );

    const showUpdateDeviceInfoModal = async (device: Device) => {
      await presentAlert({
        subHeader: "Update Device Information",
        message: "This will help you to identify your devices.",
        inputs: [
          {
            label: "Device Name",
            name: "deviceName",
            type: "text",
            placeholder: "My Smart Device",
            value: device.userDefinedName,
          },
          {
            label: "Device Group",
            name: "groupName",
            type: "text",
            placeholder: "Site X",
            value: device.groupName,
          },
        ],
        buttons: [
          {
            text: "Later",
            role: "cancel",
          },
          {
            text: "OK",
            role: "ok",
            handler: async (result) => {
              const deviceName = result["deviceName"];
              const groupName = result["groupName"];
              await presentLoading({
                message: "Updating device information",
              });
              const response =
                await defaultIotaboardClient.updateDeviceInformation(
                  device.deviceId,
                  {
                    deviceName: deviceName,
                    groupName: groupName,
                  }
                );
              await dismissLoading();
              if (response.statusCode != 200) {
                await dismissAlert();
                await presentAlert({
                  subHeader: "Failed Updating Information",
                  buttons: ["OK"],
                });
              }
              history.push("/devices");
            },
          },
        ],
        onDidDismiss: () => {
          // props.swiperInstance?.slideTo(0);
        },
      });
    };

    blePairingService.addOnInfoChanged(async (data) => {
      blePairingService.clearOnInfoCallbacks();
      if (data.status == DeviceConnectResults.IOTATECH_DEVICE_CONNECTED) {
        setProgress(1);
        const serverDeviceConnected: boolean = await deviceConnectPromise;
        if (serverDeviceConnected) {
          setProgress(2);

          const claimResult = await defaultIotaboardClient.claimDevice({
            deviceId: connectedIotaboardDeviceId,
          });

          if (claimResult.statusCode == 200 || claimResult.statusCode == 204) {
            setProgress(3);
            await blePairingService.sendDonePairing(connectedBleDeviceId);
            delay(1000);
            await blePairingService.disconnectAllBleDevices();
            setProgress(4);
            const deviceResponse = await defaultIotaboardClient.getDevice(
              connectedIotaboardDeviceId
            );
            if (deviceResponse.statusCode == 200) {
              const device = deviceResponse.data;
              if (device) {
                await showUpdateDeviceInfoModal(device);
              }
              console.log("Unable to get device information");
            }
          } else {
            await presentAlert({
              subHeader: "Something went wrong",
              message: claimResult.message,
              buttons: ["OK"],
              onDidDismiss: () => {
                props.swiperInstance?.slideTo(2);
              },
            });
          }
        } else {
          await presentAlert({
            subHeader: "Device Didn't Connect",
            message:
              "The device didn't connect to server after period of time.",
            buttons: ["OK"],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        }
      } else {
        if (data.status == DeviceConnectResults.IOTATECH_WIFI_WRONG_PASSWORD) {
          await presentAlert({
            subHeader: "Invalid Credentials",
            message: `The specified password doesn't seem to work. Please try again.`,
            buttons: ["OK"],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        } else if (
          data.status == DeviceConnectResults.IOTATECH_WIFI_DISCONNECTED
        ) {
          await presentAlert({
            subHeader: "WiFi Doesn't Work",
            message: "Device gets disconnected from the WiFi network.",
            buttons: ["OK"],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        } else if (
          data.status ==
          DeviceConnectResults.IOTATECH_MQTT_REFUSE_SERVER_UNAVAILABLE
        ) {
          await presentAlert({
            subHeader: "Server Unavailable",
            message: `Try checking the configuration. If configuration is 
              valid, the server might be temporarily down.
              If problem persists, please contact vendor.`,
            buttons: ["OK"],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        } else if (
          data.status == DeviceConnectResults.IOTATECH_MQTT_REFUSE_PROTOCOL
        ) {
          await presentAlert({
            subHeader: "Protocol Refused",
            message: `Try checking the configuration. If configuration is 
              valid, the server might be temporarily down.
              If problem persists, please contact vendor.`,
            buttons: ["OK"],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        } else if (
          data.status ==
            DeviceConnectResults.IOTATECH_MQTT_REFUSE_BAD_USERNAME ||
          data.status ==
            DeviceConnectResults.IOTATECH_MQTT_REFUSE_ID_REJECTED ||
          data.status ==
            DeviceConnectResults.IOTATECH_MQTT_REFUSE_NOT_AUTHORIZED
        ) {
          await presentAlert({
            subHeader: "Device Unrecongnized",
            message: `Server does not recognize the device. Try checking 
            your configuration or contact vendor.`,
            buttons: [
              {
                text: "OK",
              },
            ],
            onDidDismiss: () => {
              props.swiperInstance?.slideTo(2);
            },
          });
        }
      }
    });

    await blePairingService.sendWifiCredentials(
      connectedBleDeviceId,
      credentials
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ textAlign: "left" }}>
        <PreviousSlideButton
          onClick={async () => {
            props.swiperInstance?.slideTo(2);
          }}
        />
      </div>
      <div className="ion-margin">
        <IonTitle color="primary">
          <h1>Working on it</h1>
        </IonTitle>
      </div>
      <div className="ion-margin ion-padding">
        <IonText>
          We are trying to connect your device and set it up for you. This may
          take a while.
        </IonText>
      </div>
      <IonList>
        <IonItem>
          {progress > 0 ? (
            <IonIcon icon={checkmarkSharp} slot="start" />
          ) : (
            <IonSpinner slot="start" />
          )}
          <IonText>Device connecting</IonText>
        </IonItem>
        <IonItem>
          {progress > 1 ? (
            <IonIcon icon={checkmarkSharp} slot="start" />
          ) : (
            <IonSpinner slot="start" />
          )}
          <IonText>Verifying device on the server</IonText>
        </IonItem>
        <IonItem>
          {progress > 2 ? (
            <IonIcon icon={checkmarkSharp} slot="start" />
          ) : (
            <IonSpinner slot="start" />
          )}
          <IonText>Getting device ownership</IonText>
        </IonItem>
        <IonItem>
          {progress > 3 ? (
            <IonIcon icon={checkmarkSharp} slot="start" />
          ) : (
            <IonSpinner slot="start" />
          )}
          <IonText>Finishing up</IonText>
        </IonItem>
      </IonList>
    </div>
  );
};
