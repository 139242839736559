import { IonIcon, IonText } from "@ionic/react";
import { radio } from "ionicons/icons";

export const ScanModal: React.FC = () => {
  return (
    <>
      <div
        style={{
          marginTop: "72px",
          marginBottom: "72px",
        }}
      >
        <div className="ion-text-center">
          <IonIcon
            icon={radio}
            style={{
              fontSize: "80px",
            }}
            color="primary"
          />
        </div>
        <div className="ion-text-center ion-margin-top">
          <IonText color="primary">Approach Iotaboard NFC tag</IonText>
        </div>
      </div>
    </>
  );
};
