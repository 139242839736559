import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  useIonAlert,
  useIonModal,
  useIonPopover,
  useIonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { add, businessOutline, settingsSharp } from "ionicons/icons";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { Asset } from "../../services/iotaboard-client/types/asset";
import { AssetAccessPermission } from "../../services/iotaboard-client/types/asset-access-permission";
import {
  AssetOptionsPopover,
  AssetOptionsPopoverProps,
} from "./AssetOptionsPopover";
import { ScanModal } from "../../components/ScanNfcModal";
import { NFC, Ndef, NdefRecord } from "@awesome-cordova-plugins/nfc";
import {
  IotaboardNfcTagContent,
  IotaboardNfcTagType,
} from "../../services/nfc/types/iotaboard-nfc-data";
import { globalHistory } from "../../services/navigation/navigation";
import { AccessLevel } from "../../services/iotaboard-client/types/access-level";
import { bytesToHex } from "../../utilities/conversion";

export const AssetsPage: React.FC = () => {
  const [presentAlert, dismissAlert] = useIonAlert();

  const [assetPermissions, setAssetPermissions] =
    useState<AssetAccessPermission[]>();

  const [presentOptionsPopover, dismissOptionsPopover] = useIonPopover(
    AssetOptionsPopover,
    {
      onDidDismiss: (data, role) => dismissOptionsPopover(data, role),
    } as AssetOptionsPopoverProps
  );

  const [presentScanModal, dismissScanModal] = useIonModal(ScanModal);

  const [presentToast] = useIonToast();

  const [loading, setLoading] = useState<boolean>();

  const loadAssets = async () => {
    setLoading(true);
    const response = await defaultIotaboardClient.getAssets();
    if (response.statusCode == 200) {
      console.log(response.data);
      setAssetPermissions(response.data);
    } else {
      await presentAlert({
        subHeader: "Unable to get assets",
        message: response.message,
        buttons: ["OK"],
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAssets();
  }, []);

  const addAsset = async () => {
    await presentAlert({
      subHeader: "Enter new Asset Details",
      inputs: [
        {
          label: "Name",
          placeholder: "Name",
          name: "name",
        },
        {
          label: "Type",
          placeholder: "Type",
          name: "type",
        },
        {
          label: "Group",
          placeholder: "Group",
          name: "Group",
        },
      ],
      buttons: [
        {
          text: "OK",
          role: "ok",
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
      onDidDismiss: async (ev) => {
        console.log(ev.detail);
        if (ev.detail.role == "ok") {
          const name = ev.detail.data.values["name"] as string | undefined;
          const type = ev.detail.data.values["type"] as string | undefined;
          const group = ev.detail.data.values["group"] as string | undefined;
          if (name && type) {
            const response = await defaultIotaboardClient.createAsset({
              assetName: name.trim(),
              assetType: type.trim(),
              assetGroup: group?.trim(),
            });

            loadAssets();

            if (response.statusCode !== 200) {
              await dismissAlert();
              await presentAlert({
                subHeader: "Unable to Create Asset",
                message: response.message,
                buttons: ["OK"],
              });
            }
          } else {
            console.log("Invalid entry");
            await dismissAlert();
            await presentAlert({
              subHeader: "Invalid Entry",
              message:
                "Name and Type is required. Please check your entries and try again.",
              buttons: ["OK"],
            });
          }
        }
      },
    });
  };

  const createNfc = async (asset: Asset) => {
    try {
      // ensure NFC enabled
      await NFC.enabled();
      const nfcEvents = NFC.addNdefListener();
      const subscription = nfcEvents.subscribe(async (ndef) => {
        if (!ndef.tag.isWritable) {
          dismissScanModal();
          presentAlert({
            subHeader: "Cannot Write to Tag",
            message: "This tag is readonly",
            buttons: ["OK"],
          });
          return;
        }
        const uidBytes = ndef.tag.id;
        const uidStr = bytesToHex(uidBytes, "-");
        console.log(uidStr);
        const response = await defaultIotaboardClient.putAssetInTag(
          uidStr,
          asset.id
        );
        if (response.statusCode == 200) {
          await presentAlert({
            subHeader: "NFC Write Success",
            message:
              "You may want to try scanning your newly created tag to verify.",
            buttons: ["OK"],
          });
          const tagContent: IotaboardNfcTagContent = {
            id: asset.id,
            type: IotaboardNfcTagType.asset,
          };
          const payloadStr = JSON.stringify(tagContent);
          const records: NdefRecord[] = [
            Ndef.mimeMediaRecord(
              "application/x-iotaboard-nfc-data",
              payloadStr
            ),
            Ndef.androidApplicationRecord("id.iotatech.iotaboard"),
          ];
          await NFC.write(records);
          await dismissScanModal();
        } else {
          await presentAlert({
            subHeader: "Cannot Write NFC",
            message: response.message,
            buttons: ["OK"],
          });
        }
        await dismissScanModal();
      });
      await presentScanModal({
        cssClass: "popup-dialog",
        onDidDismiss: () => {
          subscription.unsubscribe();
        },
      });
    } catch (e) {
      if (e == "NO_NFC") {
        presentAlert({
          subHeader: "NFC Unavailable",
          message: "NFC is not available on this device.",
          buttons: ["OK"],
        });
      } else if (e == "NFC_DISABLED") {
        presentAlert({
          subHeader: "NFC Disabled",
          message: "Please enable NFC to perform this operation.",
          buttons: [
            {
              text: "Dismiss",
            },
            {
              text: "Open Settings",
              handler: async () => {
                await NFC.showSettings();
              },
            },
          ],
        });
      }
    }
  };

  const isAdminOrHigher = (permission: AssetAccessPermission) => {
    return (
      permission.accessLevel == AccessLevel.admin ||
      permission.accessLevel == AccessLevel.owner
    );
  };

  const itemClickHandler = (permission: AssetAccessPermission) => {
    if (isAdminOrHigher(permission)) {
      return () => {
        globalHistory.push(`/maintenance/${permission.assetId}`);
      };
    } else {
      return () =>
        presentToast({
          duration: 3000,
          message:
            "You need to scan the asset Iotaboard NFC tag on maintenance page to see logs.",
          color: "primary",
          buttons: [
            {
              text: "Scan",
              handler: () => globalHistory.push(`/maintenance`),
            },
          ],
        });
    }
  };

  const assetList = (): JSX.Element => {
    return (
      <IonList>
        {assetPermissions!.map((p) => (
          <IonItem
            key={p.id}
            button={isAdminOrHigher(p)}
            onClick={itemClickHandler(p)}
          >
            <IonIcon slot="start" icon={businessOutline} />
            <IonLabel>
              <h3>{p.asset.name}</h3>
              <p>{p.asset.type}</p>
            </IonLabel>
            {isAdminOrHigher(p) && (
              <IonButton
                slot="end"
                fill="clear"
                style={{ height: "100%" }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  presentOptionsPopover({
                    event: ev as any,
                    onDidDismiss: (ev) => {
                      if (ev.detail.role) {
                        if (ev.detail.role == "create-nfc") {
                          createNfc(p.asset);
                        } else if (ev.detail.role == "manage-permissions") {
                          globalHistory.push(
                            `/assets-permissions/${p.asset.id}`
                          );
                        }
                      }
                    },
                  });
                }}
              >
                <IonIcon
                  icon={settingsSharp}
                  style={{
                    margin: "12px",
                  }}
                />
              </IonButton>
            )}
          </IonItem>
        ))}
      </IonList>
    );
  };

  const assetContent = (): JSX.Element => {
    return assetPermissions ? (
      assetPermissions.length > 0 ? (
        assetList()
      ) : (
        <div className="ion-text-center">You have no assets</div>
      )
    ) : (
      <div></div>
    );
  };

  return (
    <IonPage>
      <MainAppBar title="Assets" />
      <IonContent>
        {loading ? (
          <div className="container">
            <IonSpinner />
          </div>
        ) : (
          assetContent()
        )}
        <IonFab vertical="bottom" horizontal="end" style={{ margin: "8px" }}>
          <IonFabButton onClick={() => addAsset()}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};
