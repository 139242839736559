import { IonButton, IonIcon } from "@ionic/react";
import { arrowBackSharp } from "ionicons/icons";

export const PreviousSlideButton: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <IonButton fill="clear" onClick={() => props.onClick()}>
      <IonIcon icon={arrowBackSharp} size="large" />
    </IonButton>
  );
};
