import { NdefEvent } from "@awesome-cordova-plugins/nfc";
import { APIOperationResult } from "../../services/iotaboard-client/types/api-operation-result";
import { IotaboardNfcTagContent } from "../../services/nfc/types/iotaboard-nfc-data";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { bytesToHex } from "../../utilities/conversion";
import { arrowBack } from "ionicons/icons";

export interface TagInspectionModalProps {
  ndefEvent: NdefEvent;
  response: APIOperationResult<IotaboardNfcTagContent>;
  dismiss: () => void;
}

export const TagInspectionModal: React.FC<TagInspectionModalProps> = (
  props
) => {
  const dataItem = (title: string, data: any) => {
    return (
      <IonItem button>
        <IonLabel className="ion-text-wrap">
          <h3>{title}</h3>
          <p>{data}</p>
        </IonLabel>
      </IonItem>
    );
  };

  const serverDataItems = () => {
    console.log(props.response);
    if (props.response.statusCode == 200 && props.response.data) {
      const serverContent: IotaboardNfcTagContent = props.response.data;
      return (
        <>
          {Object.keys(serverContent).map((key) => {
            const value = serverContent[key as keyof IotaboardNfcTagContent];
            if (typeof value === "object") {
              return dataItem(key, JSON.stringify(value));
            } else {
              return dataItem(key, value);
            }
          })}
        </>
      );
    }
    return (
      <IonItem>
        <IonLabel>
          <h3>{props.response.message}</h3>
        </IonLabel>
      </IonItem>
    );
  };

  const ndefDataItems = () => {
    const tag = props.ndefEvent.tag;
    return (
      <>
        {dataItem("UID", bytesToHex(tag.id, "-"))}
        {dataItem("Writable", new String(tag.isWritable))}
        {dataItem("Tech Types", tag.techTypes.join("; "))}
        <IonListHeader color="tertiary">Records</IonListHeader>
        {tag.ndefMessage
          ? tag.ndefMessage.map((record) => {
              return dataItem(
                String.fromCharCode(...record.type),
                String.fromCharCode(...record.payload)
              );
            })
          : dataItem("No records", "")}
      </>
    );
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="ion-padding-start"
              onClick={() => props.dismiss()}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Tag Inspection</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonListHeader color="tertiary">Server Data</IonListHeader>
          {serverDataItems()}
          <IonListHeader color="tertiary">NDEF Data</IonListHeader>
          {ndefDataItems()}
        </IonList>
      </IonContent>
    </>
  );
};
