import { History } from "history";
import { ConfigurationModel } from "../configuration/configuration-model";
import { IotaboardClient } from "../iotaboard-client";
import { IotaboardRealtimeClient } from "../realtime";
import { NotificationCenter } from "../notification-center/NotificationCenter";

export interface Interops {
  iotaboardInterops: RegisteredInterops;
}

export interface RemoteDashboardBackgroundInitFnOptions {
  interops: RegisteredInterops;
  dashboardId?: string;
  assetId?: string;
}

export type RemoteDashboardBackgroundInitFn = (
  options: RemoteDashboardBackgroundInitFnOptions
) => void | Promise<any> | (() => void) | (() => Promise<any>);

export interface RegisteredInterops {
  defaultIotaboardClient: IotaboardClient;
  defaultIotaboardRealtimeClient: IotaboardRealtimeClient;
  loadConfiguration: () => ConfigurationModel;
  saveConfiguration: (configuration: ConfigurationModel) => void;
  navigationHistory: History;
  defaultNotificationCenter: NotificationCenter;
}

export type GlobalWithInterop = typeof global & Interops;

export const registerInterops = (
  options: RegisteredInterops
): GlobalWithInterop => {
  const globalWithInterop = global as unknown as GlobalWithInterop;
  globalWithInterop.iotaboardInterops = options;
  return globalWithInterop;
};

export const getInterops = () => {
  return (global as GlobalWithInterop).iotaboardInterops;
};
