import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonSpinner,
  useIonAlert,
  useIonModal,
  useIonPopover,
} from "@ionic/react";
import {
  addSharp,
  codeSlashOutline,
  gridOutline,
  settingsSharp,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import MainAppBar from "../../components/MainAppBar/MainAppBar";
import { loadConfiguration } from "../../services/configuration";
import { ConfigurationModel } from "../../services/configuration/configuration-model";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { APIOperationResult } from "../../services/iotaboard-client/types/api-operation-result";
import { Dashboard } from "../../services/iotaboard-client/types/dashboard";
import {
  DashboardOptionsPopover,
  DashboardOptionsPopoverProps,
} from "./DashboardOptionsPopover";
import { UserInfo } from "../../services/iotaboard-client/types/user-info";
import {
  DashboardShareModal,
  DashboardShareModalProps,
} from "./DashboardShareModal";

export const DashboardsPage: React.FC = () => {
  const loc = useLocation();
  const history = useHistory();
  const [presentAlert, dismissAlert] = useIonAlert();

  const [configuration, setConfiguration] = useState<ConfigurationModel>(
    loadConfiguration()
  );

  const [userInfo, setUserInfo] = useState<UserInfo>();

  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [fetchDashboardsPromise, setFetchDashboardsPromise] =
    useState<Promise<APIOperationResult<Dashboard[]>>>();

  const fetchDashboards = async () => {
    console.log("Getting dashboards");
    const promise = defaultIotaboardClient.getDashboards();
    setFetchDashboardsPromise(promise);
    const response = await promise;
    if (response.statusCode == 200) {
      setDashboards(response.data!);
      console.log(response.data);
    } else {
      await dismissAlert;
      await presentAlert({
        subHeader: "An Error Occured",
        message: response.message,
        buttons: ["OK"],
      });
    }
    setFetchDashboardsPromise(undefined);
  };

  // Effect to listen to route changes
  // to reload configuration and dashboards
  // for devmode changes
  useEffect(() => {
    if (loc.pathname == "/dashboards") {
      setConfiguration(loadConfiguration());
      fetchDashboards();
      console.log("Dashboard rerenders");
    }
  }, [loc.pathname]);

  const getUserInfo = async () => {
    const response = await defaultIotaboardClient.getThisUserInfo();
    if (response.statusCode == 200) {
      setUserInfo(response.data);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const [activeDashboardOptionsId, setActiveDashboardOptionsId] =
    useState<string>();

  const [presentOptionsPopover, dismissOptionsPopover] = useIonPopover(
    DashboardOptionsPopover,
    {
      dashboardId: activeDashboardOptionsId,
      dismiss: (data, role) => dismissOptionsPopover(data, role),
    } as DashboardOptionsPopoverProps
  );

  const [presentDashboardShareModal, dismissDashboardShareModal] = useIonModal(
    DashboardShareModal,
    {
      dismiss: (data, role) => dismissDashboardShareModal(data, role),
      dashboard: dashboards.find(
        (d) => d.dashboardId == activeDashboardOptionsId
      ),
    } as DashboardShareModalProps
  );

  const showDashboardOptions = (ev: any, dashboard: Dashboard) => {
    ev.stopPropagation();
    setActiveDashboardOptionsId(dashboard.dashboardId);
    presentOptionsPopover({
      event: ev,
      onDidDismiss: async (ev) => {
        if (ev.detail.role && ev.detail.data) {
          if (ev.detail.role == "delete") {
            const response = await defaultIotaboardClient.deleteDashboard(
              ev.detail.data
            );
            console.log("Delete response", response);
            if (response.statusCode == 200 || response.statusCode == 202) {
              await fetchDashboards();
            }
          } else if (ev.detail.role == "rename") {
            const dashboardIdForRename = ev.detail.data;
            await presentAlert({
              subHeader: "Rename Dashboard",
              message: "Enter the new name for the dashboard",
              inputs: [
                {
                  type: "text",
                  name: "dashboard-name",
                  placeholder: "A New Name",
                },
              ],
              buttons: [
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Rename",
                  role: "rename",
                },
              ],
              onDidDismiss: async (ev) => {
                console.log("Rename event details", ev.detail);
                if (
                  ev.detail.role == "rename" &&
                  ev.detail.data &&
                  ev.detail.data.values &&
                  ev.detail.data.values["dashboard-name"]
                ) {
                  const newName = ev.detail.data.values["dashboard-name"];
                  await defaultIotaboardClient.renameDashboard(
                    dashboardIdForRename,
                    newName
                  );
                  await fetchDashboards();
                }
              },
            });
          } else if (ev.detail.role == "share") {
            presentDashboardShareModal({
              cssClass: "full-height-dialog",
            });
          }
        }
      },
    });
  };

  return (
    <IonPage>
      <MainAppBar title="Dashboards" />
      <IonContent>
        {configuration.developerMode &&
          configuration.developerDashboards &&
          configuration.developerDashboards.length > 0 && (
            <IonList>
              <IonListHeader>
                <IonLabel>Development Dashboards</IonLabel>
              </IonListHeader>
              {configuration.developerDashboards.map((url) => (
                <IonItem
                  button
                  key={url}
                  onClick={() => {
                    presentAlert({
                      subHeader: "Leaving Main Application",
                      message: `You will be directed to the url ${url}. Make sure you trust this domain and location. Iotaboard hot reload will not take effect when you are in remote dashboard.`,
                      buttons: [
                        {
                          text: "Leave",
                          handler: () => {
                            window.location.href = url;
                          },
                        },
                        {
                          text: "Cancel",
                        },
                      ],
                    });
                  }}
                >
                  <IonIcon icon={codeSlashOutline} slot="start" />
                  <IonLabel>{url}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}

        {fetchDashboardsPromise ? (
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonSpinner />
            </IonRow>
          </IonGrid>
        ) : dashboards.length > 0 ? (
          <IonList>
            <IonListHeader>Your Dashboards</IonListHeader>
            {dashboards.map((dashboard) => (
              <IonItem
                button
                key={dashboard.dashboardId}
                onClick={() => {
                  history.push(
                    `/dashboards/${dashboard.dashboardTemplateId}/${dashboard.dashboardId}`
                  );
                }}
              >
                <IonIcon icon={gridOutline} slot="start" color="primary" />
                <IonLabel>
                  <h3>{dashboard.dashboardName}</h3>
                  <p>{dashboard.dashboardTemplate.templateName}</p>
                </IonLabel>
                {userInfo && dashboard.ownerUsername == userInfo.username && (
                  <IonButton
                    fill="clear"
                    onClick={(ev) => {
                      showDashboardOptions(ev, dashboard);
                    }}
                    style={{ height: "90%" }}
                    slot="end"
                  >
                    <IonIcon icon={settingsSharp} style={{ padding: "12px" }} />
                  </IonButton>
                )}
              </IonItem>
            ))}
          </IonList>
        ) : (
          <div className="container">
            <p>You have no dashboards.</p>
            <IonButton
              className="ion-margin"
              color="primary"
              onClick={() => history.push("/dashboard-add")}
            >
              Create One!
            </IonButton>
          </div>
        )}

        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton
            className="ion-margin"
            onClick={() => {
              history.push("/dashboard-add");
            }}
          >
            <IonIcon icon={addSharp} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};
