import { IonButton, IonContent, IonPage, useIonAlert } from "@ionic/react";
import { defaultNotificationCenter } from "../../services/notification-center/NotificationCenter";
import MainAppBar from "../../components/MainAppBar/MainAppBar";

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IonImg } from "@ionic/react";
import "../Page.css";
import { useState } from "react";

import { NFC } from "@awesome-cordova-plugins/nfc";
import { globalHistory } from "../../services/navigation/navigation";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { defaultIotaboardClient } from "../../services/iotaboard-client";
import { bytesToHex } from "../../utilities/conversion";
import { Clipboard } from "@capacitor/clipboard";
console.log(NFC);
export const ExperimentsPage: React.FC = () => {
  const [imgUrl, setImgUrl] = useState<string>();
  const [presentAlert, dismissAlert] = useIonAlert();
  const scanNfc = async () => {
    const scanEvents = NFC.addNdefListener(
      () => console.log("NDEF Listener set"),
      () => {
        console.error("NDEF listener can't be set");
      }
    );
    await presentAlert({
      message: "Approach an NFC Tag",
      onDidDismiss: () => {
        supscription.unsubscribe();
      },
    });
    const supscription = scanEvents.subscribe(async (ev) => {
      await dismissAlert();
      const uidStr = bytesToHex(ev.tag.id, "-");
      await presentAlert({
        subHeader: "NFC Detected",
        message: `UID: ${uidStr}`,
        buttons: [
          {
            text: "Copy",
            handler: async () => {
              await Clipboard.write({
                string: uidStr,
              });
            },
          },
          {
            text: "OK",
            role: "cancel",
          },
        ],
      });
    });
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  };

  const writeFile = async () => {
    const response =
      await defaultIotaboardClient.getAssetMaintenanceLogFileContent(
        "646c3c76323c0e3671dc5c6c"
      );

    if (response.statusCode == 200) {
      const blob = response.data as Blob;
      const base64FileStr = await blobToBase64(blob);
      console.log(blob.type);
      const filename = "testimage." + blob.type.split("/")[1];

      try {
        await Filesystem.mkdir({
          directory: Directory.Documents,
          path: "Iotaboard",
        });
      } catch (e) {
        if (e != "Directory Exists") {
          presentAlert({
            subHeader: "Unable to Write File",
            message: e as string,
            buttons: ["OK"],
          });
        }
      }

      await Filesystem.writeFile({
        directory: Directory.Documents,
        path: `Iotaboard/${filename}`,
        data: base64FileStr,
      });
    }
  };

  return (
    <IonPage>
      <MainAppBar title="Experiments" />
      <IonContent>
        <div className="container">
          <IonButton
            onClick={async () => {
              await defaultNotificationCenter.newNotificationEntry({
                body: "See your new device, See your new device, See your new device, See your new device",
                title: "New Device",
                extra: {
                  navigateTo: "/devices",
                  color: "primary",
                  ts: new Date().getTime(),
                },
              });
            }}
          >
            Notify
          </IonButton>
          <IonButton
            onClick={async () => {
              const image = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                quality: 90,
                allowEditing: true,
                source: CameraSource.Camera,
              });

              const imageUrl = image.webPath;
              console.log(imageUrl);
              setImgUrl(imageUrl);
            }}
          >
            Camera
          </IonButton>
          <IonButton onClick={() => scanNfc()}>NFC</IonButton>
          <IonImg src={imgUrl}></IonImg>
          <IonButton
            onClick={() => {
              globalHistory.push(`/maintenance/6464ab0e9f662a22cb78a7cd`);
            }}
          >
            Maintenance
          </IonButton>
          <IonButton
            onClick={() => {
              writeFile();
            }}
          >
            Write File
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};
