/**
 * These dependencies will be made available to the Remote Components.
 */
module.exports = {
  resolve: {
    react: require("react"),
    "@ionic/react": require("@ionic/react"),
    "@ionic/react-router": require("@ionic/react-router"),
    "@microsoft/signalr": require("@microsoft/signalr"),
    "date-fns": require("date-fns"),
    ionicons: require("ionicons"),
    "react-dom": require("react-dom"),
    "react-router": require("react-router"),
    "react-router-dom": require("react-router-dom"),
    "@capacitor/camera": require("@capacitor/camera"),
    "@capacitor/filesystem": require("@capacitor/filesystem"),
    "@capacitor/clipboard": require("@capacitor/clipboard"),
    "@capacitor/share": require("@capacitor/share"),
    "@capacitor/local-notifications": require("@capacitor/local-notifications"),
    "@capacitor-community/file-opener": require("@capacitor-community/file-opener"),
    "@awesome-cordova-plugins/core": require("@awesome-cordova-plugins/core")
  },
};
