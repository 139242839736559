import { ScanResult } from "@capacitor-community/bluetooth-le";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonSpinner,
  IonTitle,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { hardwareChip, hardwareChipOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { getBlePairingService } from "../../../services/ble-pairing";
import { delay } from "../../../utilities/delay";
import { CrossSlideInterfacing } from "../cross-slide-interfacing";
import { PreviousSlideButton } from "../PreviousSlideButton/PreviousSlideButton";

export const DeviceListSlide: React.FC<CrossSlideInterfacing> = (props) => {
  const [deviceList, setDeviceList] = useState<ScanResult[]>([]);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert, dismissAlert] = useIonAlert();

  const startScan = async () => {
    setDeviceList([]);
    const blePairingService = getBlePairingService();
    const canStartScan = await blePairingService.startScanBleDevices({
      bleDeviceListUpdated: (scanResults) => {
        setDeviceList([...scanResults]);
      },
    });
    if (!canStartScan) {
      presentAlert({
        message: "Unable to start scan",
        buttons: ["OK"],
      });
    }
  };

  const stopScan = async () => {
    const blePairingService = getBlePairingService();
    await blePairingService.stopScanBleDevices();
  };

  const onDeviceDisconnected = async (
    bleDeviceId: string,
    intentional: boolean
  ) => {
    if (!intentional) {
      await dismissLoading();

      await presentAlert({
        message: "Lost connection to device",
        buttons: ["OK"],
        onDidDismiss: async () => {
          await delay(500);
          props.swiperInstance?.slideTo(1);
        },
      });
      const blePairingService = getBlePairingService();
      await blePairingService.disconnectAllBleDevices();
      startScan();
    }
  };

  const onConnectError = async (e: Error) => {
    await dismissLoading();
    await presentAlert({
      subHeader: "Unable to Reach Device",
      message: `${e.message} Make sure device is powered on and is in good range.`,
      buttons: ["Ok"],
    });
  };

  const connectToDevice = async (bleDeviceId: string) => {
    await presentLoading({
      message: `Connecting to device`,
      spinner: "circular",
    });

    const blePairingService = getBlePairingService();
    await blePairingService.connectToBleDevice({
      bleDeviceId: bleDeviceId,
      onDisconnect: onDeviceDisconnected,
      errorCallback: onConnectError,
      onConnectSucces: () => {
        props.swiperInstance?.slideTo(2);
      },
    });
    await dismissLoading();
  };

  // Effect to start scanning when this slide is entered
  useEffect(() => {
    console.log("Mounted");
    if (props.currentIndex == 1) {
      startScan();
    }
  }, [props, props.currentIndex]);

  /// Effect to stop scanning when this component is unmounted
  useEffect(() => {
    return () => {
      console.log("Calling stop scan");
      stopScan();
    };
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        className="ion-margin-top ion-padding-top"
        style={{ textAlign: "left" }}
      >
        <PreviousSlideButton
          onClick={async () => {
            const blePairingService = getBlePairingService();
            await blePairingService.stopScanBleDevices();
            props.swiperInstance?.slideTo(0);
          }}
        />
      </div>
      <div className="ion-margin ion-padding-top">
        <IonIcon icon={hardwareChipOutline} size="large" color="primary" />
      </div>
      <div className="ion-margin">
        <IonTitle color="primary">Nearby Devices</IonTitle>
      </div>
      <IonButton fill="clear" color="danger">
        Stop Scan
      </IonButton>

      <div className="ion-margin-top">
        <IonGrid>
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol size="auto">
              <IonSpinner color="primary" />
            </IonCol>
            <IonCol size="auto">
              <IonNote className="ion-margin">
                Iotaboard is searching for devices
              </IonNote>
            </IonCol>
          </IonRow>
        </IonGrid>
        {deviceList.length > 0 && (
          <IonList>
            {deviceList.map((device) => (
              <IonItem
                button
                key={device.device.deviceId}
                onClick={() => connectToDevice(device.device.deviceId)}
              >
                <IonIcon icon={hardwareChip} slot="start" />
                <IonGrid>
                  <IonRow>
                    <IonLabel>{device.device.name}</IonLabel>
                  </IonRow>
                  <IonRow>
                    <IonNote>{device.device.deviceId}</IonNote>
                  </IonRow>
                </IonGrid>
              </IonItem>
            ))}
          </IonList>
        )}
      </div>
    </div>
  );
};
