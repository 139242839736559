/**
 * @typedef IotaboardChangeLog
 * @property {"breaking-change" | "bug-fix" | "enhancement"} type
 * @property {string} message
 */

/**
 * @typedef IotaboardVersionInformation
 * @property {number} versionCode
 * @property {string} versionName
 * @property {IotaboardChangeLog[]} changeLogs
 */

/**
 * @type IotaboardVersionInformation
 */
export const versionInformation = {
  versionCode: 32,
  versionName: "0.4.12",
  changeLogs: [
    {
      type: "breaking-change",
      message:
        "There is internal change that affects all dashboards.\nYour dashboards will stop working soon if you don't upgrade.",
    },
    {
      type: "bug-fix",
      message: "Fixes background tasks not stopped after logout.",
    },
    {
      type: "bug-fix",
      message: "Fixes background tasks not stopped after logout.",
    },
    {
      type: "enhancement",
      message: "Fixes truncated changelog.",
    },
    {
      type: "enhancement",
      message: "Users now can update account information from Accounts menu.",
    },
    {
      type: "enhancement",
      message: "Notifications now display its time if provided.",
    },
    {
      type: "enhancement",
      message: "Added realtime status indicator on menu.",
    },
    {
      type: "enhancement",
      message: "Improve realtime reconnection handling.",
    },
    {
      type: "bug-fix",
      message: "Fix reconnection stopped retrying.",
    },
    {
      type: "enhancement",
      message: "Realtime reconnection enhancement experiment.",
    },
    {
      type: "enhancement",
      message: "Notification UI/UX enhancements",
    },
  ],
};
