import { IonContent } from "@ionic/react";
import QRCode from "react-qr-code";
import QrCode from "react-qr-code";

export interface QrCodePopoverProps {
  dismiss: (data?: any, role?: string) => void | Promise<any>;
  username: string;
}

export const QrCodePopover: React.FC<QrCodePopoverProps> = (props) => {
  return (
    <IonContent>
      <div
        style={{
          backgroundColor: "white",
          padding: "16px",
        }}
      >
        <QRCode
          value={props.username}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        />
      </div>
    </IonContent>
  );
};
