import { IonContent, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { UserInfo } from "../../services/iotaboard-client/types/user-info";
import { removeOutline, shield, shieldHalfOutline } from "ionicons/icons";
import { AccessLevel } from "../../services/iotaboard-client/types/access-level";
import { PermissionViewModel } from "./permission-view-model";

export interface UserOptionPopoverProps {
  permission: PermissionViewModel;
  finish: (data?: any, role?: string) => void | Promise<any>;
}

export const UserOptionPopover: React.FC<UserOptionPopoverProps> = (props) => {
  console.log(props);
  return (
    <IonContent>
      <IonList>
        {(props.permission.accessLevel === undefined || props.permission.accessLevel != AccessLevel.guest) && (
          <IonItem
            button
            onClick={() => {
              props.finish(props.permission.username, "make-guest");
            }}
          >
            <IonIcon slot="start" icon={shieldHalfOutline} />
            <IonLabel>Make Guest</IonLabel>
          </IonItem>
        )}
        {(props.permission.accessLevel === undefined || props.permission.accessLevel != AccessLevel.admin) && (
          <IonItem
            button
            onClick={() => {
              props.finish(props.permission.username, "make-admin");
            }}
          >
            <IonIcon slot="start" icon={shieldHalfOutline} color="warning" />
            <IonLabel color="warning">Make Admin</IonLabel>
          </IonItem>
        )}
        {props.permission.accessLevel !== undefined && (
          <IonItem
            button
            onClick={() => {
              props.finish(props.permission.username, "remove");
            }}
          >
            <IonIcon slot="start" icon={removeOutline} color="danger" />
            <IonLabel color={"danger"}>Remove Permission</IonLabel>
          </IonItem>
        )}
      </IonList>
    </IonContent>
  );
};
