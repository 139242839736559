import { APIOperationResult } from "./types/api-operation-result";
import { ClaimDeviceOptions } from "./types/claim-device-options";
import { Credentials } from "./types/credentials";
import { UserInfo } from "./types/user-info";

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosResponse,
  AxiosResponseTransformer,
  InternalAxiosRequestConfig,
} from "axios";

import { ConfigurationModel } from "../configuration/configuration-model";
import { getHttpBaseUrlFromConfig } from "./utilities";
import { UserDeviceListOptions } from "./types/user-device-list-options";
import { Device } from "./types/device";
import { ChangePasswordOptions } from "./types/change-password-options";
import { CreateDashboardOptions } from "./types/create-dashboard-options";
import { Dashboard } from "./types/dashboard";
import { DashboardTemplate } from "./types/dashboard-template";
import { DeviceLatestRecords } from "./types/device-latest-records";
import { DeviceRecordGroup } from "./types/device-record-group";
import { GetDashboardsOptions } from "./types/get-dashboard-options";
import { RecordFilterViewModel } from "./types/record-filter-view-model";
import { UpdateDeviceOptions } from "./types/update-device-options";
import { NON_HTTP_ERROR } from "./constants";
import { CreateAssetOptions } from "./types/create-asset-options";
import { AssetAccessPermission } from "./types/asset-access-permission";
import { GetAssetsFilterOptions } from "./types/get-assets-filter-options";
import { loadConfiguration, saveConfiguration } from "../configuration";
import { AssetUserInfo } from "./types/asset-user-info";
import { AddAssetPermissionOptions } from "./types/add-asset-permission-options";
import { AssetMainenanceLogOptions } from "./types/asset-maintenance-log-options";
import { AssetMaintenanceLog } from "./types/asset-maintenance-log";
import { AssetMaintenanceLogsFilters } from "./types/asset-maintenance-logs-filters";
import { AssetMaintenanceFile } from "./types/asset-maintenance-file";
import { IotaboardNfcTagContent } from "../nfc/types/iotaboard-nfc-data";
import { globalHistory } from "../navigation/navigation";
import { AssetMaintenanceFileOptions } from "./types/asset-maintenance-file-options";
import { Asset } from "./types/asset";
import { IotaboardVersionInformation } from "../versioning";
import { UpdateUserInfoOptions } from "./types/update-user-info-options";

export class IotaboardClient {
  private _token?: string | undefined;

  get token() {
    return this._token;
  }

  axiosInstance?: AxiosInstance;

  private interceptReq(config: InternalAxiosRequestConfig) {
    const configuration = loadConfiguration();
    if (!configuration.cacheTs) {
      return config;
    }

    if (config.params) {
      config.params = {
        ...config.params,
        cacheTs: configuration.cacheTs,
      };
    } else {
      config.params = {
        cacheTs: configuration.cacheTs,
      };
    }
    return config;
  }

  private interceptRes(res: AxiosResponse) {
    if (res.status == 401) {
      const configuration = loadConfiguration();
      configuration.tokenCache = undefined;
      saveConfiguration(configuration);
      globalHistory.replace("/");
    }
    return res;
  }

  async initializeWithToken(
    configuration: ConfigurationModel
  ): Promise<APIOperationResult<any>> {
    if (!configuration.serverHost || !configuration.tokenCache) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: "Invalid configuration",
      };
    }

    // Use the token
    this._token = configuration.tokenCache;

    // Setup protocol prefix
    const httpBaseUrl = getHttpBaseUrlFromConfig(configuration);

    this.axiosInstance = axios.create({
      baseURL: httpBaseUrl,
      validateStatus: this.statusValidator,
    });

    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this._token}`;

    this.axiosInstance.interceptors.request.use(this.interceptReq);
    this.axiosInstance.interceptors.response.use(this.interceptRes);

    const response = await this.getThisUserInfo();
    if (response.statusCode == 200) {
      return {
        message: "Success",
        statusCode: 200,
      };
    } else if (response.statusCode == 401) {
      return {
        message: "Invalid token",
        statusCode: 401,
      };
    } else {
      return {
        message: "An error occured",
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  responseTransformer: AxiosResponseTransformer = (data, headers, status) => {
    const contentType = headers.get("Content-Type");
    if (contentType && contentType.toString().includes("application/json")) {
      return JSON.parse(data);
    }

    return data;
  };

  statusValidator = () => true;

  async initializeWithCredentials(
    options: Credentials,
    configuration: ConfigurationModel
  ): Promise<APIOperationResult<any>> {
    if (!options || !options.username || !options.password) {
      return {
        statusCode: 400,
        message: "Invalid configuration or credential",
      };
    }

    const httpBaseUrl = getHttpBaseUrlFromConfig(configuration);

    this.axiosInstance = axios.create({
      baseURL: httpBaseUrl,
      validateStatus: this.statusValidator,
    });

    try {
      console.log("Validating credentials");
      const result = await this.axiosInstance.post(
        "user/auth",
        {
          usernameOrEmail: options.username,
          password: options.password,
        },
        {
          transformResponse: this.responseTransformer,
        }
      );

      console.log(result);

      if (result.status == 200 || result.status == 201) {
        this._token = `${result.data["token"]}`;
        this.axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this._token}`;

        this.axiosInstance.interceptors.request.use(this.interceptReq);
        this.axiosInstance.interceptors.response.use(this.interceptRes);
        return {
          statusCode: result.status,
          message: "Success",
        };
      } else {
        return {
          statusCode: result.status,
          message: result.data,
        };
      }
    } catch (err) {
      console.log(err);
      return {
        message: (err as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async claimDevice(
    options: ClaimDeviceOptions
  ): Promise<APIOperationResult<any>> {
    try {
      const claimResult = await this.axiosInstance!.get("device/claim", {
        params: {
          deviceId: options.deviceId,
        },
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
      });

      if (claimResult?.status == 200 || claimResult?.status == 204) {
        return {
          statusCode: 200,
          message: "Claim device success",
        };
      } else {
        return {
          statusCode: claimResult.status,
          message: claimResult.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getThisUserInfo(): Promise<APIOperationResult<UserInfo>> {
    try {
      const response = await this.axiosInstance!.get("user/me", {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
      });

      if (response.status == 200) {
        return {
          statusCode: 200,
          message: "User Information",
          data: response.data as UserInfo,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getUserInfo(
    username: string
  ): Promise<APIOperationResult<AssetUserInfo>> {
    try {
      const response = await this.axiosInstance!.get(
        `user/get-info/${username}`,
        {
          transformResponse: this.responseTransformer,
          validateStatus: this.statusValidator,
        }
      );

      if (response.status == 200) {
        return {
          statusCode: 200,
          message: "User Information",
          data: response.data as UserInfo,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getUserDeviceList(
    options?: UserDeviceListOptions
  ): Promise<APIOperationResult<Device[]>> {
    try {
      const response = await this.axiosInstance!.get(
        "device/user-device-list",
        {
          params: options,
          validateStatus: this.statusValidator,
          transformResponse: this.responseTransformer,
        }
      );
      if (response.status == 200) {
        return {
          statusCode: 200,
          message: "Devices",
          data: response.data,
        };
      } else if (response.status == 204) {
        return {
          statusCode: 204,
          message: "No devices",
        };
      } else {
        return {
          statusCode: response.status,
          message:
            typeof response.data == "string"
              ? response.data
              : "An error occured",
          data: typeof response.data != "string" ? response.data : undefined,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDashboardTemplates(options: {
    dashboardTemplateName?: string;
    tags?: string[];
  }): Promise<APIOperationResult<DashboardTemplate[]>> {
    try {
      const response = await this.axiosInstance!.get("dashboard-templates", {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
        params: options,
      });
      if (response.status == 200) {
        return {
          statusCode: 200,
          message: "Dashboard templates",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async createDashboard(
    options: CreateDashboardOptions
  ): Promise<APIOperationResult<Dashboard>> {
    try {
      const response = await this.axiosInstance!.post("dashboard", options, {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
      });
      return {
        statusCode: response.status,
        message: "Dashboard created",
        data: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async renameDashboard(
    dashboardId: string,
    newName: string
  ): Promise<APIOperationResult<Dashboard>> {
    try {
      const response = await this.axiosInstance!.patch(
        `dashboard/${dashboardId}`,
        null,
        {
          params: {
            newName,
          },
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDashboards(
    options?: GetDashboardsOptions
  ): Promise<APIOperationResult<Dashboard[]>> {
    try {
      const response = await this.axiosInstance!.get("dashboard", {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
        params: options,
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Dashboards",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDashboard<T = any>(
    id: string
  ): Promise<APIOperationResult<Dashboard<T>>> {
    try {
      const response = await this.axiosInstance!.get(`dashboard/${id}`, {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Dashboard details",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDashboardTemplateModule(
    dashboardTemplateId: string
  ): Promise<APIOperationResult<any>> {
    try {
      const response = await this.axiosInstance!.get(
        `dashboard-templates/${dashboardTemplateId}/main.js`
      );
      if (response.status == 200) {
        return {
          message: "Dashboard module",
          statusCode: response.status,
          data: response.data,
        };
      } else {
        return {
          message: response.data,
          statusCode: response.status,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async updateDashboardSettings<T>(
    dashboardId: string,
    settings: T
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.post(
        `dashboard/settings/${dashboardId}`,
        settings
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async updateDeviceInformation(
    deviceId: string,
    options: UpdateDeviceOptions
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.patch(
        `device/${deviceId}`,
        options
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async deleteDashboard(
    dashboardId: string
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.delete(
        `dashboard/${dashboardId}`
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      console.error(e);
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDeviceTelemetries(
    deviceId: string,
    options?: RecordFilterViewModel
  ): Promise<APIOperationResult<DeviceRecordGroup[]>> {
    try {
      const response = await this.axiosInstance!.get(
        `device/telemetry/${deviceId}`,
        {
          params: options,
        }
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Device telemetries",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDeviceStatuses(
    deviceId: string,
    options: RecordFilterViewModel
  ): Promise<APIOperationResult<DeviceRecordGroup[]>> {
    try {
      const response = await this.axiosInstance!.get(
        `device/status/${deviceId}`,
        {
          params: options,
        }
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Device statuses",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDeviceLatestTelemetry(
    deviceId: string
  ): Promise<APIOperationResult<DeviceLatestRecords>> {
    try {
      const response = await this.axiosInstance!.get(
        `device/telemetry/${deviceId}/latest`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Device latest telemetry",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDeviceLatestStatus(
    deviceId: string
  ): Promise<APIOperationResult<DeviceLatestRecords>> {
    try {
      const response = await this.axiosInstance!.get(
        `device/status/${deviceId}/latest`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Device latest status",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getDevice(deviceId: string): Promise<APIOperationResult<Device>> {
    try {
      const response = await this.axiosInstance!.get(`device/${deviceId}`);
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Device information",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async changePassword(
    options: ChangePasswordOptions
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.patch(`user/passwd`, options);
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Password changed successfully",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async clearCache(): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.get(`general/clear-cache`);
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Clear cache received",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async createAsset(
    options: CreateAssetOptions
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.post("assets", options, {
        transformResponse: this.responseTransformer,
        validateStatus: this.statusValidator,
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getAsset<T = any>(
    assetId: string
  ): Promise<APIOperationResult<Asset<T>>> {
    try {
      const response = await this.axiosInstance!.get(`assets/${assetId}`);
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getAssets(
    options?: GetAssetsFilterOptions
  ): Promise<APIOperationResult<AssetAccessPermission[]>> {
    try {
      const response = await this.axiosInstance!.get(`assets`, {
        params: options,
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Assets",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getThisUserAssetAccessPermission(
    assetId: string
  ): Promise<APIOperationResult<AssetAccessPermission>> {
    try {
      const response = await this.axiosInstance!.get(
        `assets/${assetId}/permissions/this-user`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset Permission",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getAssetAccessPermissions(
    assetId: string
  ): Promise<APIOperationResult<AssetAccessPermission[]>> {
    try {
      const response = await this.axiosInstance!.get(
        `assets/${assetId}/permissions`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset Permission",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getMaintenanceDashboardTemplate(
    type: string
  ): Promise<APIOperationResult<DashboardTemplate>> {
    try {
      const response = await this.axiosInstance!.get(
        `dashboard-templates/maintenance-dashboard-template/${type}`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Maintenance Dashboard Template",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async updateAssetProperties(assetId: string, properties: any) {
    try {
      const response = await this.axiosInstance!.patch(
        `assets/${assetId}/properties`,
        {
          properties,
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async addAssetPermission(
    options: AddAssetPermissionOptions
  ): Promise<APIOperationResult<Dashboard>> {
    try {
      const response = await this.axiosInstance!.post(
        "assets/add-permission",
        options,
        {
          transformResponse: this.responseTransformer,
          validateStatus: this.statusValidator,
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async removeAssetPermission(
    assetId: string,
    username: string
  ): Promise<APIOperationResult<Dashboard>> {
    try {
      const response = await this.axiosInstance!.delete(
        `assets/remove-permission/${assetId}/${username}`,
        {
          transformResponse: this.responseTransformer,
          validateStatus: this.statusValidator,
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async createAssetMaintenanceLog<T = any>(
    assetId: string,
    options: AssetMainenanceLogOptions<T>
  ): Promise<APIOperationResult<AssetMaintenanceLog<T>>> {
    try {
      const formData: FormData = new FormData();
      formData.append("Description", options.description);
      if (options.properties) {
        const propertiesJson = JSON.stringify(options.properties);
        formData.append("PropertiesJson", propertiesJson);
      }

      options.maintenanceFiles.forEach((entry, index) => {
        formData.append(
          `MaintenanceFiles[${index}].File`,
          entry.file,
          entry.fileName
        );
        if (entry.description) {
          formData.append(
            `MaintenanceFiles[${index}].Description`,
            entry.description
          );
        }
        if (entry.tag) {
          formData.append(`MaintenanceFiles[${index}].Tag`, entry.tag);
        }
      });

      const response = await this.axiosInstance!.postForm(
        `assets/maintenance/${assetId}`,
        formData,
        {
          transformResponse: this.responseTransformer,
          validateStatus: this.statusValidator,
        }
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "New Log",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getAssetMaintenanceLogs(
    assetId: string,
    filters?: AssetMaintenanceLogsFilters
  ): Promise<APIOperationResult<AssetMaintenanceLog[]>> {
    try {
      const response = await this.axiosInstance!.get(`assets/${assetId}/logs`, {
        params: filters,
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset Maintenance Logs",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getAssetMaintenanceLogFiles(
    logId: string
  ): Promise<APIOperationResult<AssetMaintenanceFile[]>> {
    try {
      const response = await this.axiosInstance!.get(
        `assets/logs/${logId}/files`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset Maintenance Logs",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getLog(
    logId: string
  ): Promise<APIOperationResult<AssetMaintenanceLog>> {
    try {
      const response = await this.axiosInstance!.get(`assets/logs/${logId}`);
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Log Information",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async updateLogProperties(
    logId: string,
    newProperties: object
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.patch(
        `assets/logs/${logId}/properties`,
        newProperties
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getAssetMaintenanceLogFileContent(
    fileId: string
  ): Promise<APIOperationResult<Blob>> {
    try {
      const response = await this.axiosInstance!.get(`assets/files/${fileId}`, {
        responseType: "blob",
      });
      if (response.status == 200) {
        return {
          statusCode: response.status,
          data: response.data,
          message: "Asset Maintenance Logs",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async addLogFile(
    logId: string,
    options: AssetMaintenanceFileOptions
  ): Promise<APIOperationResult<undefined>> {
    const formData: FormData = new FormData();
    formData.append("File", options.file, options.fileName);
    if (options.description) {
      formData.append("Description", options.description);
    }
    if (options.tag) {
      formData.append("Tag", options.tag);
    }
    try {
      const response = await this.axiosInstance!.postForm(
        `assets/logs/${logId}/files`,
        formData
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Success",
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  private dashboardTemplateFetchFailure?: (e: Error) => void | Promise<any>;

  setDashobardTemplateFetchFailure(
    onFailure: (e: Error) => void | Promise<any>
  ) {
    this.dashboardTemplateFetchFailure = onFailure;
  }

  async getDashboardTemplateFile(url: string) {
    try {
      const response = await this.axiosInstance!.get(url);
      if (response.status == 200) {
        return response.data;
      } else {
        if (this.dashboardTemplateFetchFailure) {
          this.dashboardTemplateFetchFailure({
            name: "Unexpected Response",
            message: "Dashboard template returns unexpected response",
          });
        }
      }
    } catch (e) {
      if (this.dashboardTemplateFetchFailure) {
        this.dashboardTemplateFetchFailure({
          name: "Unable to Fetch Dashboard Template",
          message: (e as AxiosError).message,
        });
      }
    }
  }

  async putAssetInTag(
    tagUid: string,
    assetId: string
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.put(
        `nfc-tags/${tagUid}/asset/${assetId}`
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async getTagContent(
    tagUid: string
  ): Promise<APIOperationResult<IotaboardNfcTagContent>> {
    try {
      const response = await this.axiosInstance!.get(
        `nfc-tags/${tagUid}/content`
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Tag content",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async registerNfcTag(tagUid: string): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.post(`nfc-tags/${tagUid}`);
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        message: (e as AxiosError).message,
        statusCode: NON_HTTP_ERROR,
      };
    }
  }

  async assignUserToDashboard(options: {
    dashboardId: string;
    adminUsernames?: string[];
    guestUsernames?: string[];
  }): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.post(
        `dashboard/${options.dashboardId}/assign`,
        {
          adminUsernames: options.adminUsernames,
          guestUsernames: options.guestUsernames,
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async unassignUserFromDashboard(
    dashboardId: string,
    usernames: string[]
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.post(
        `dashboard/${dashboardId}/unassign`,
        {
          usernames,
        }
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async getUpstreamVersionInfo(
    platform: "web" | "android"
  ): Promise<APIOperationResult<IotaboardVersionInformation>> {
    try {
      const baseURL = getHttpBaseUrlFromConfig(loadConfiguration(), true);
      const response = await this.axiosInstance!.get(
        `_client/${platform}-client-version`,
        {
          baseURL: baseURL,
        }
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Android Client Version",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async downloadLatestApk(options: {
    onDownloadProgress: (ev: AxiosProgressEvent) => void | Promise<any>;
  }): Promise<APIOperationResult<Blob>> {
    try {
      const baseURL = getHttpBaseUrlFromConfig(loadConfiguration(), true);
      const response = await this.axiosInstance!.get(
        `_client/downloads/android/latest`,
        {
          baseURL: baseURL,
          onDownloadProgress: options.onDownloadProgress,
          responseType: "blob",
        }
      );
      if (response.status == 200) {
        return {
          statusCode: response.status,
          message: "Android Client Package",
          data: response.data,
        };
      } else {
        return {
          statusCode: response.status,
          message: response.data,
        };
      }
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }

  async updateUserInformation(
    options: UpdateUserInfoOptions
  ): Promise<APIOperationResult<undefined>> {
    try {
      const response = await this.axiosInstance!.patch(
        `user/update-user-info`,
        options
      );
      return {
        statusCode: response.status,
        message: response.data,
      };
    } catch (e) {
      return {
        statusCode: NON_HTTP_ERROR,
        message: (e as AxiosError).message,
      };
    }
  }
}
