import { IonPage } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import "./swiper-style-overrides.css";
import { Swiper } from "swiper/types";
import { Virtual } from "swiper";
import { GettingStarted } from "./GettingStarted/GettingStarted";
import { DeviceListSlide } from "./DeviceList/DeviceListSlide";
import { WifiListSlide } from "./WifiListSlide/WifiListSlide";
import { WifiPasswordSlide } from "./WifiPasswordSlide/WifiPasswordSlide";
import { ConnectProgressSlide } from "./ConnectProgressSlide/ConnectProgressSlide";
import { Credentials } from "../../services/ble-pairing";
import { useHistory } from "react-router";

export const PairDeviceSwipePage: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [ssidToRequest, setSsidToRequest] = useState<string>("");

  const passwordResolver = useRef<(password?: string) => void>();

  const [wifiPasswordSlideReturnIndex, setWifiPasswordSlideReturnIndex] =
    useState(0);

  const [toConnectCredentials, setToConnectCredentials] =
    useState<Credentials>();

  const history = useHistory();

  const requestPassword = (
    ssid: string,
    returnSlideIndex: number
  ): Promise<string | undefined> => {
    setSsidToRequest(ssid);
    setWifiPasswordSlideReturnIndex(returnSlideIndex);
    swiperInstance?.slideTo(3);
    const promise = new Promise<string | undefined>((resolver) => {
      console.log("Passing");
      passwordResolver.current = resolver;
    });
    console.log("Setting passwordCallback state");
    return promise;
  };

  const connectToDevice = (credentials: Credentials) => {
    setToConnectCredentials(credentials);
    swiperInstance?.slideTo(4);
  };

  useEffect(() => {
    console.log("The password callback", passwordResolver);
  });

  return (
    <IonPage>
      <SwiperComponent
        modules={[Virtual]}
        onSwiper={(sw) => setSwiperInstance(sw)}
        onSlideChange={(sw) => setCurrentIndex(sw.activeIndex)}
        allowTouchMove={false}
      >
        <SwiperSlide>
          <GettingStarted
            swiperInstance={swiperInstance}
            currentIndex={currentIndex}
          />
        </SwiperSlide>
        <SwiperSlide>
          <DeviceListSlide
            swiperInstance={swiperInstance}
            currentIndex={currentIndex}
          />
        </SwiperSlide>
        <SwiperSlide>
          <WifiListSlide
            swiperInstance={swiperInstance}
            currentIndex={currentIndex}
            requestPassswordCallback={requestPassword}
            requestConnectCallback={connectToDevice}
          />
        </SwiperSlide>
        <SwiperSlide>
          <WifiPasswordSlide
            passwordResolver={passwordResolver.current}
            swiperInstance={swiperInstance}
            currentIndex={currentIndex}
            returnIndex={wifiPasswordSlideReturnIndex}
            ssid={ssidToRequest}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ConnectProgressSlide
            swiperInstance={swiperInstance}
            currentIndex={currentIndex}
            credentials={toConnectCredentials}
            successCallback={() => history.replace("/devices")}
          />
        </SwiperSlide>
      </SwiperComponent>
    </IonPage>
  );
};
