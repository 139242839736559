import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  useIonLoading,
  useIonToast,
} from "@ionic/react";

import { useHistory, useLocation } from "react-router-dom";
import {
  alertCircleOutline,
  analytics,
  businessOutline,
  checkmarkCircleOutline,
  cloudDoneOutline,
  cloudOfflineOutline,
  cloudUploadOutline,
  hardwareChipOutline,
  helpCircleOutline,
  informationCircle,
  logOutOutline,
  logoReact,
  personCircleOutline,
  radioOutline,
  receiptOutline,
} from "ionicons/icons";
import "./Menu.css";
import React, { useEffect, useReducer, useState } from "react";
import {
  loadConfiguration,
  saveConfiguration,
} from "../../services/configuration";

import jwt_decode from "jwt-decode";
import { defaultIotaboardRealtimeClient } from "../../services/realtime";
import { stopRemoteDashboardBackground } from "../../services/remote-dashboard-background";
import { HubConnectionState } from "@microsoft/signalr";

let _updateMenuEntries: () => void;

export const updateMenuEntries = () => _updateMenuEntries();

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

interface MenuProps {
  id?: string;
}

const Menu: React.FC<MenuProps> = (props) => {
  const [_ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  _updateMenuEntries = () => forceUpdate();

  const location = useLocation();
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast, dismissToast] = useIonToast();

  const configuration = loadConfiguration();
  const token = configuration.tokenCache;
  let name = "";
  let email = "";

  if (token) {
    const decoded = jwt_decode<any>(token);
    name = decoded["name"];
    email = decoded["email"];
  }

  const appPages: AppPage[] = [
    {
      title: "Dashboards",
      url: "/dashboards",
      iosIcon: analytics,
      mdIcon: analytics,
    },
    {
      title: "Assets",
      url: "/assets",
      iosIcon: businessOutline,
      mdIcon: businessOutline,
    },
    {
      title: "Maintenance",
      url: "/maintenance",
      iosIcon: receiptOutline,
      mdIcon: receiptOutline,
    },
    {
      title: "Devices",
      url: "/devices",
      iosIcon: hardwareChipOutline,
      mdIcon: hardwareChipOutline,
    },
    {
      title: "Account",
      url: "/account",
      iosIcon: personCircleOutline,
      mdIcon: personCircleOutline,
    },
  ];

  if (token) {
    try {
      const decodedToken = jwt_decode(token) as any;
      const role = decodedToken.role;
      if (role && role == "Admin") {
        appPages.push({
          title: "NFC Tags",
          url: "/nfc-tags",
          mdIcon: radioOutline,
          iosIcon: radioOutline,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  if (configuration.developerMode) {
    appPages.push({
      title: "Experiments",
      url: "/test",
      iosIcon: logoReact,
      mdIcon: logoReact,
    });
  }

  const [realtimeState, setRealtimeState] = useState<HubConnectionState>();

  useEffect(() => {
    defaultIotaboardRealtimeClient
      .getConnectionState()
      .then((state) => setRealtimeState(state));

    const realtimeStateChangeCallback = (state: HubConnectionState) => {
      setRealtimeState(state);
      console.log("Connection state changed", state);
    };

    defaultIotaboardRealtimeClient.addConnectionStateChangeEventListener(
      realtimeStateChangeCallback
    );

    return () => {
      defaultIotaboardRealtimeClient.removeConnectionStateChangeEventListener(
        realtimeStateChangeCallback
      );
    };
  }, []);

  const connectionStateButton = () => {
    if (realtimeState == HubConnectionState.Connected) {
      return (
        <IonButton
          color="success"
          fill="clear"
          onClick={async () => {
            await dismissToast();
            await presentToast({
              message: "Realtime connection online",
              duration: 3000,
              icon: checkmarkCircleOutline,
              color: "success",
            });
          }}
        >
          <IonIcon icon={cloudDoneOutline} />
        </IonButton>
      );
    }
    if (!realtimeState || realtimeState == HubConnectionState.Disconnected) {
      return (
        <IonButton
          color="danger"
          fill="clear"
          onClick={async () => {
            await dismissToast();
            await presentToast({
              message: "Realtime connection lost",
              duration: 3000,
              icon: alertCircleOutline,
              color: "danger",
              buttons: [
                {
                  text: "Reload",
                  side: "end",
                  handler: () => {
                    window.location.replace("/");
                  },
                },
              ],
            });
          }}
        >
          <IonIcon icon={cloudOfflineOutline} />
        </IonButton>
      );
    }

    if (
      realtimeState == HubConnectionState.Connecting ||
      realtimeState == HubConnectionState.Reconnecting
    ) {
      return (
        <IonButton
          color="warning"
          fill="clear"
          onClick={async () => {
            await dismissToast();
            await presentToast({
              message: "Realtime connection connecting...",
              duration: 3000,
              icon: cloudUploadOutline,
              color: "warning",
            });
          }}
        >
          <IonIcon icon={cloudUploadOutline} />
        </IonButton>
      );
    }

    return (
      <IonButton
        color="warning"
        fill="clear"
        onClick={async () => {
          await dismissToast();
          await presentToast({
            message: "Realtime connection status unknown",
            duration: 3000,
            icon: helpCircleOutline,
            color: "warning",
          });
        }}
      >
        <IonIcon icon={helpCircleOutline} />
      </IonButton>
    );
  };

  return (
    <IonMenu
      id={props.id}
      disabled={location.pathname == "/login"}
      contentId="main"
      type="overlay"
    >
      <IonContent>
        <IonList id="inbox-list" className="menu-border-bottom">
          <IonListHeader className="header-name">{name}</IonListHeader>
          <IonNote>{email}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  button
                  lines="none"
                  detail={false}
                  onClick={() => {
                    history.replace(appPage.url);
                  }}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonList className="menu-border-bottom">
          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              onClick={() => {
                history.push("/about");
              }}
              lines="none"
              className={location.pathname === "/about" ? "selected" : ""}
            >
              <IonIcon slot="start" icon={informationCircle} />
              <IonLabel>About</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonList>
          <IonMenuToggle autoHide={false}>
            <IonItem
              lines="none"
              detail={false}
              button
              onClick={async () => {
                await presentLoading("Logging out");
                const configuration = loadConfiguration();
                configuration.tokenCache = undefined;
                saveConfiguration(configuration);
                await defaultIotaboardRealtimeClient.closeRealtimeConnections();
                await stopRemoteDashboardBackground();
                await dismissLoading();
                history.replace("/login");
              }}
            >
              <IonIcon color="danger" slot="start" icon={logOutOutline} />
              <IonLabel color="danger">Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            marginRight: 16,
            marginBottom: 16,
          }}
        >
          {connectionStateButton()}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
