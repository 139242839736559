import {
  createRemoteComponent,
  createRequires,
} from "@paciolan/remote-component";
import { resolve } from "../../remote-component.config.js";
import { defaultIotaboardClient } from "../../services/iotaboard-client";

const requires = createRequires(resolve as any);

const fetcher = (url: string) =>
  defaultIotaboardClient.getDashboardTemplateFile(url);

export const DashboardRemoteComponent = createRemoteComponent({
  requires,
  fetcher,
});
