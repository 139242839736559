import { LocalNotifications } from "@capacitor/local-notifications";
import { globalHistory } from "../navigation/navigation";
import { loadConfiguration, saveConfiguration } from "../configuration";

export interface NotificationCenterEntryExtra {
  navigateTo?: string;
  color?: string;
  ts: number;
}

export interface NotificationCenterEntry {
  id?: number;
  title: string;
  body: string;
  extra?: NotificationCenterEntryExtra;
  action?: any[];
}

export class NotificationCenter {
  private notificationEntries: NotificationCenterEntry[] =
    loadConfiguration().notificationEntries ?? [];

  private updateNotificationEntriesOnConfiguration(
    entries: NotificationCenterEntry[]
  ) {
    const config = loadConfiguration();
    config.notificationEntries = entries;
    saveConfiguration(config);
  }

  getNotificationEntries() {
    return this.notificationEntries;
  }

  private getNewId() {
    if (this.notificationEntries.length == 0) {
      return 0;
    } else {
      const ids = this.notificationEntries.map((e) => e.id!);
      return Math.max(...ids) + 1;
    }
  }

  async newNotificationEntry(entry: NotificationCenterEntry) {
    if (!entry.id) {
      entry.id = this.getNewId();
    }
    this.notificationEntries.push(entry);
    this.updateNotificationEntriesOnConfiguration(this.notificationEntries);
    this.notificationListChangedCallbacks.forEach((c) => c());
    await LocalNotifications.schedule({
      notifications: [
        {
          id: entry.id,
          body: entry.body,
          title: entry.title,
          extra: entry.extra,
        },
      ],
    });
  }

  removeNotificationEntry(entry: NotificationCenterEntry) {
    this.notificationEntries = this.notificationEntries.filter(
      (e) => e != entry
    );
    this.updateNotificationEntriesOnConfiguration(this.notificationEntries);
    this.notificationListChangedCallbacks.forEach((c) => c());
  }

  clearNotificationEntries() {
    this.notificationEntries = [];
    this.updateNotificationEntriesOnConfiguration(this.notificationEntries);
    this.notificationListChangedCallbacks.forEach((c) => c());
  }

  removeNotificationEntryById(id: number) {
    this.notificationEntries = this.notificationEntries.filter(
      (e) => e.id != id
    );
    this.updateNotificationEntriesOnConfiguration(this.notificationEntries);
    this.notificationListChangedCallbacks.forEach((c) => c());
  }

  processNotificationExtra(extra: NotificationCenterEntryExtra) {
    if (extra.navigateTo) {
      globalHistory.push(extra.navigateTo);
    }
  }

  processNotification(entry: NotificationCenterEntry) {
    if (entry.extra) {
      this.processNotificationExtra(entry.extra);
    }
    this.removeNotificationEntry(entry);
  }

  private notificationListChangedCallbacks: (() => Promise<any>)[] = [];

  addEventListener(
    eventName: "notification-list-changed",
    callback: () => Promise<any>
  ) {
    if (eventName == "notification-list-changed") {
      this.notificationListChangedCallbacks.push(callback);
    }
  }

  removeEventListener(
    eventName: "notification-list-changed",
    callback: () => Promise<any>
  ) {
    if (eventName == "notification-list-changed") {
      this.notificationListChangedCallbacks =
        this.notificationListChangedCallbacks.filter((c) => c != callback);
    }
  }

  constructor() {
    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (action) => {
        const extra: NotificationCenterEntryExtra | undefined =
          action.notification.extra;
        if (extra) {
          this.processNotificationExtra(extra);
        }
      }
    );
  }
}

export const defaultNotificationCenter = new NotificationCenter();
